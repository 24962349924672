export const ROLES = {
  ADMIN: { id: 1, name: "Admin" },
  PROJECT_MANAGER: { id: 2, name: "Project Manager" },
  SUPPLY_MANAGER: { id: 3, name: "Supply Manager" },
};

export const API_VERSION_PREFIX = "/api/v1";

export const ROUTES = {
  ROOT: "/",
  LOGOUT: "/logout",
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/set-new-password/*",
  ACTIVATE_USER: "/activate-user/*",
  TERMS_OF_USE: "/legal/terms-of-use",
  PRIVACY_POLICY: "/legal/privacy-policy",
  // Project manager routes
  USER_PROJECT_SELECTOR: `/projects`,
  USER_MY_ACCOUNT: `/my-account`,
  USER_CHANGE_PASSWORD: `/change-password`,
  USER_TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  USER_PRIVACY_POLICY: `/privacy-policy`,
  USER_ACCOUNT_SETTINGS: `/account-settings`,
  DIRECT_MESSAGES: (id) => `/project/${id}/direct-messages`,
  NOTIFICATIONS: `/notifications/`,
  ORDER_DETAILS_PROJECT_MANAGER: `/order-details`,

  // Admin routes
  // FOR DEMO replace analytic route
  // ADMIN_ANALYTICS: "/dashboard/analytics",
  ADMIN_ANALYTICS: "/dashboard/customers",
  ADMIN_CUSTOMERS: "/dashboard/customers",
  ADMIN_DRIVERS: "/dashboard/drivers",
  ADMIN_APPROVE_ACCOUNTS: "/dashboard/approve-accounts",
  ADMIN_CUSTOMERS_DETAILS: (customerId) => `/dashboard/customers/${customerId}/details`,
  ADMIN_DRIVERS_DETAILS: (driverId) => `/dashboard/drivers/${driverId}/details`,
  ADMIN_STORE_MANAGEMENT: "/dashboard/store-management",
  ADMIN_ADD_TANK: "/dashboard/store-management/add-new-tank",
  ADMIN_EDIT_NOTIFICATION: (notificationId) => `/dashboard/push-notification/edit-notification/${(notificationId)}`,
  ADMIN_NEW_NOTIFICATION: "/dashboard/push-notification/add-new-notification",
  ADMIN_PUSH_NOTIFICATION: "/dashboard/push-notifications",
  ADMIN_EDIT_TANK: (tankId) => `/dashboard/store-management/edit-tank/${(tankId)}`,
  ADMIN_ADD_STORE: "/dashboard/store-management/add-new-store",
  ADMIN_EDIT_STORE: (storeId) => `/dashboard/store-management/edit-store/${(storeId)}`,
  ADMIN_ALL_USERS: "/dashboard/all-users",
  ADMIN_CREATE_NEW_USER: "/dashboard/create-user-account",
  ADMIN_CREATE_NEW_PRODUCT: "/management/manage-products/new",
  ADMIN_PRODUCTS: "/management/manage-products/",
  ADMIN_CREATE_NEW_BRAND: "/management/manage-brands/new",
  ADMIN_BRAND: "/management/manage-brands",

  // Order management
  ADMIN_ORDERS: "/managements/orders-orders",
  ORDER_DETAILS: (customerId) => `/managements/orders-orders/${customerId}/details`,
  ADMIN_ORDER_REQUESTS: "/managements/order-requests",
  ADMIN_ORDER_REQUESTS_DETAILS: (orderId) => `/managements/order-requests/${orderId}/details`,
};

export const PM_TASKS = {
  SHARE: "share",
  APPROVE_ORDER: "approve_order",
  EDIT_ORDER: "edit_order",
  APPROVE_CONTRACT: "approve_contract",
  REJECT: "reject",
};

export const VENDOR_TASKS = {
  APPROVE_ORDER: "approve_order",
  EDIT_ORDER: "edit_order",
  APPROVE_CONTRACT: "approve_contract",
  REJECT: "reject",
  MARK_FULFILLED: "mark_fulfilled",
};
