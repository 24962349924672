import pxToRem from "../../assets/theme/functions/pxToRem";
import { Box } from "@mui/material";
import React from "react";
import pencilIcon from "../../assets/icons/pencil2.svg";
import trashIcon from "../../assets/icons/trash2.svg";
import IconButton from "@mui/material/IconButton";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import { ROUTES } from "../../services/constants";


export const MODAL_NAME_STORE = {
  CANCEL_MODAL: "cancel",
  DELETE_MODAL: "delete",
  REACTIVE_MODAL: "reactivate",
};

export const dataTableModel = {
  columns: [
    { Header: "Title", accessor: "title",  disableOrdering: true },
    { Header: "Message", accessor: "description",  disableOrdering: true },
    { Header: "Send Date", accessor: "scheduled_datetime",  disableOrdering: true },
    { Header: "Users", accessor: "send_to",  disableOrdering: true },
    { Header: "Action", accessor: "actions",  disableOrdering: true },
  ],
  rows: [],
};

const popOver = (item, setOpenModal, setSelectedItem, navigate) => {
  return (
    <Box display={"flex"} alingItems={"center"} gap={1}>
      <IconButton
        sx={{
          borderRadius: "10px",
          background: "#364861",
        }}
        onClick={() => {
          setSelectedItem(item);
          navigate(ROUTES.ADMIN_EDIT_NOTIFICATION(item.id),
            { state: { ...item, actions: null } },
          );
        }}
      >
        <img src={pencilIcon} alt={"edit"} />
      </IconButton>

      <IconButton
        sx={{
          borderRadius: "10px",
          background: "#364861",
        }}
        onClick={() => {
          setSelectedItem(item);
          setOpenModal(true);
        }}
      >
        <img src={trashIcon} alt="edit" />
      </IconButton>
    </Box>
  );
};

export const renderTableRow = (item, setOpenModal, setSelectedItem, navigate) => {
  item.title = item.title || "No data";
  item.description = item.description || "No data";
  item.scheduled_datetime = item.scheduled_datetime || "No data";
  // item.send_to = (item.send_to && item.send_to.length > 0) ? item.send_to.join(' ') : "No data";
  if (item.send_to && item.send_to.length > 0) {
    item.send_to = item.send_to.map(value => {
      switch (value) {
        case 1:
          return "Customers";
        case 2:
          return " Company Drivers";
        case 3:
          return "Regular Drivers";
        default:
          return "No data";
      }
    }).join('');
  }
  item.actions = (popOver(item, setOpenModal, setSelectedItem, navigate));
  return item;
};