export const ORDER_STATUS = {
  1: "Submitted",
  2: "Accepted",
  3: "Delivered",
  4: "Completed",
  5: "Cancelled",
};

//Order Status Colors
export const STATUS_COLORS = {
  1: "#FFBB50",
  2: "#44CB61",
  3: "#0095FE",
  4: "#0095FE",
  5: "#C03403",
};

export const ORDER_TYPE = {
  SUBMITTED: 1,
  ACCEPTED_BY_ADMIN: 2,
  ACCEPTED_BY_CUSTOMER: 2,
  DELIVERED: 3,
  COMPLETED: 4,
  CANCELLED: 5,
};

export const FEEDBACK_STATUS = {
  PENDING: 1,
  REPLIED: 2,
}


export const USER_TYPE = {
  CUSTOMER: 1,
  DRIVER: 2,
}

export const USER_STATUS = {
  CREATED: 1,
  COMPLETED: 2,
  CANCELLED: 3,
}

export const DRIVER_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
}

export const MODAL_NAME = {
  CANCEL_MODAL: "Block",
  DELETE_MODAL: "Delete",
  REACTIVE_MODAL: "Reactivate",
}

export const ORDER_PERIOD_FILTER = {
  TODAY: "today",
  TOMORROW: "tomorrow",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
}


