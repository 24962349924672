import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useState } from "react";
import { useApi } from "../../services/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import prohibitIcon from "../../assets/icons/Prohibit.svg"
import trashIcon from "../../assets/icons/TrashSimple.svg"
import { FormControl, Grid, MenuItem, Select, useMediaQuery } from "@mui/material";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { ROUTES } from "../../services/constants";
import { DRIVER_STATUS, MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import ProductOrderListPage from "../../pages/dashboard/orderManagements/orders";
import reactive from "../../assets/icons/reactiveUser.svg";
import MDButton from "components/MDButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const USER_FIELDS = {
  email: "Email",
  phone_number: "Contact number",
  date_of_birth: "Date of birth",
  address: "Address",
  city: "City",
  state: "State",
  zip: "ZIP",
  country: "Country",
  customer_type: "Customer Type",
}

export const CUSTOM_INFO_FIELDS = {
  delivery_type: "Delivery Type",
  tanks_owned: "Tanks Owned",
  frequency: "Delivery frequency",
  preferred_days: "Preferred day(s)",
  need_cabinet: "Need storage cabinet",
  size: "Cabinet size",
  multiple_addresses: "Multiple addresses",
  needs: "Needs",
}

export const customerTypeOptions = [
  { id: 1, name: 'Residential' },
  { id: 2, name: 'Commercial' },
];

function AdminCustomersDetails() {
  const api = useApi()
  const navigate = useNavigate();
  const { state } = useLocation();
  const [modalName, setModalName] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState(state?.client_type ?? customerTypeOptions[0]?.id);
  const [open, setOpen] = useState(false);

  const isSmallScreen = useMediaQuery('(min-width:320px) and (max-width:769px)');


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const blockUser = () => {
    setLoading(true)
    api.blockUser(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false)
      },
      errorMessage: 'Error blocking user',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const updateClientType = () => {
    const data = {
      client_type: selectedOption,
    }

    setLoading(true);
    api.isUpdateClientType(state?.customer_id, data).handle({
      onSuccess: (res) => {
        console.log("res", res);
        // navigate("/dashboard/customer/");
      },
      onError: (err) => {
        console.log("err", err);
      },
      errorMessage: "Error update client type request",
      successMessage: "update client type request approved",
      onFinally: () => setLoading(false),
    });
  }

  const deleteAccount = () => {
    setLoading(true)
    api.deleteAccount(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false)
      },
      errorMessage: 'Error deactivating user',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const reactivateUser = () => {
    setLoading(true)
    api.reactivateUser(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false)
      },
      errorMessage: 'Error reactivating user',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const handleConfirmModal = () => {
    if (modalName === MODAL_NAME.CANCEL_MODAL) blockUser()
    else if (modalName === MODAL_NAME.DELETE_MODAL) deleteAccount()
    else if (modalName === MODAL_NAME.REACTIVE_MODAL) reactivateUser()
    navigate(ROUTES.ADMIN_CUSTOMERS)
  }

  return (
    <AdminLayout
      title={'Customers'}
    >
      <MDBox width={"100%"}>
        <MDBox
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"120px"}
          borderRadius={"10px"}
          mb={5}
          sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <MDTypography color={"black"} variant={"h3"} ml={2}>
            {state?.name}
          </MDTypography>
          <MDBox display={"flex"} alignItems={"center"} gap={1} mr={2}>
            {/*<IconButton*/}
            {/*  onClick={()=>{}}*/}
            {/*>*/}
            {/*  <img src={warningIcon} alt="edit" />*/}
            {/*</IconButton>*/}
            {state?.user_status === USER_STATUS.CANCELLED ?
              <IconButton
                onClick={() => {
                  setOpenModal(true)
                  setModalName(MODAL_NAME.REACTIVE_MODAL)
                }}
              >
                <img src={reactive} alt="edit" />
              </IconButton>
              :
              <IconButton
                onClick={() => {
                  setOpenModal(true)
                  setModalName(MODAL_NAME.CANCEL_MODAL)
                }}
              >
                <img src={prohibitIcon} alt="edit" />
              </IconButton>
            }
            <IconButton
              onClick={() => {
                setModalName(MODAL_NAME.DELETE_MODAL)
                setOpenModal(true)
              }}
            >
              <img src={trashIcon} alt="edit" />
            </IconButton>
          </MDBox>
        </MDBox>

        <Grid container justifyContent={"center"} gap={2} >
          <Grid container item xs={12} md={5.8} display={'flex'} flexWrap={'nowrap'} direction={'column'}
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)", paddingBottom: isSmallScreen ? '85px' : 3 }}
            p={3}
            position={'relative'}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Account Information
              </MDTypography>
            </MDBox>
            <Grid container>
              {Object.entries(USER_FIELDS).map(([key, label], index) => (
                <>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#F5F5F5" : "white"} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} paddingRight={1}>
                    <MDTypography sx={{ textAlign: "right", overflow: 'hidden', textOverflow: 'ellipsis' }}>{state[key]}</MDTypography>
                    {key === 'customer_type' && (
                      <FormControl sx={{ m: 1, minWidth: 90 }}>
                        <Select
                          value={selectedOption}
                          onChange={handleChange}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          displayEmpty
                          sx={{ textAlign: 'right', color: '#090909', fontSize: 20, fontWeight: 500 }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          IconComponent={() => open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        >
                          {customerTypeOptions.map((option) => {
                            return (
                              <MenuItem value={option.id}>
                                {option.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
            <MDButton onClick={() => updateClientType()} variant="contained" color="primary" sx={{ borderRadius: 5, width: 'fit-content', }}>
              Save
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={5.5}
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            p={3}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Custom Information
              </MDTypography>
            </MDBox>
            <Grid container>
              {Object.entries(CUSTOM_INFO_FIELDS).map(([key, label], index) => (
                <>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography sx={{ textAlign: "right", overflow: 'hidden', textOverflow: 'ellipsis' }}>No data</MDTypography>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {state?.is_active && (
          <MDBox mt={7}>
            <ProductOrderListPage clientId={state.customer_id} customer_id={true} />
          </MDBox>)}
      </MDBox>
      <ConfirmModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleConfirmModal}
        title={`${modalName} user?`}
        subtitle={`Are you sure you want to ${modalName} ${state?.name}?`}
      />
    </AdminLayout>
  );
}

export default AdminCustomersDetails;
