import pxToRem from "../../assets/theme/functions/pxToRem";
import { Box } from "@mui/material";
import MDButton from "../../components/MDButton";
import React from "react";
import warningIcon from "../../assets/icons/warningCircle.svg"
import prohibitIcon from "../../assets/icons/Prohibit.svg"
import trashIcon from "../../assets/icons/TrashSimple.svg"
import IconButton from "@mui/material/IconButton";
import { ROUTES } from "../../services/constants";
import MDTypography from "../../components/MDTypography";
import { DRIVER_STATUS, MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import reactive from "../../assets/icons/reactiveUser.svg";

export const dataTableModel = {
  columns: [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone Number", accessor: "phone_number" , disableOrdering: true },
    { Header: "Status", accessor: "status" },
    { Header: "Action", accessor: "actions",  disableOrdering: true },
  ],
  rows: [],
};

const popOver = (item, setOpenModal, setSelectedItem, navigate, setModalName) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {item.user_status === USER_STATUS.CANCELLED ?
        <IconButton
          onClick={() => {
            setSelectedItem(item)
            setOpenModal(true)
            setModalName(MODAL_NAME.REACTIVE_MODAL)
          }}
        >
          <img src={reactive} alt="edit" />
        </IconButton>
        :
        <IconButton
          onClick={() => {
            setSelectedItem(item)
            setOpenModal(true)
            setModalName(MODAL_NAME.CANCEL_MODAL)
          }}
        >
          <img src={prohibitIcon} alt="edit" />
        </IconButton>
      }
      <IconButton
        onClick={() => {
          setSelectedItem(item)
          setOpenModal(true)
          setModalName(MODAL_NAME.DELETE_MODAL)
        }}
      >
        <img src={trashIcon} alt="edit" />
      </IconButton>
      <MDButton
        color={"primary"}
        sx={{ borderRadius: "20px" }}
        onClick={() =>
          navigate(
            ROUTES.ADMIN_DRIVERS_DETAILS(item.id),
            {
              state: {
                ...item,
                actions: null,
                status: null,
                city: item.city ? item.city.label : null,
                state: item.state ? item.state.label : null,
              },
            }
          )
        }
      >
        View
      </MDButton>
    </Box>
  )
}

const STATUS_OPTIONS_COLORS = {
  [DRIVER_STATUS.PENDING]: "warning",
  [DRIVER_STATUS.APPROVED]: "info",
  [DRIVER_STATUS.DENIED]: "error",
}

const renderStatus = (item) => {
  return (

    <MDTypography color={STATUS_OPTIONS_COLORS[item.driver_status]} fontSize={"16px"}>
      {item.driver_status_name}
    </MDTypography>
  )
}

export const renderTableRow = (item, setOpenModal, setSelectedItem, navigate, setModalName) => {
  item.status = renderStatus(item)
  item.actions = (popOver(item, setOpenModal, setSelectedItem, navigate, setModalName))
  return item
}