import React, { useEffect, useState, useRef  } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar, Doughnut, Line} from "react-chartjs-2";
import { Box, Grid, Typography } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { useApi } from "../../services/helpers";
import { USER_TYPE } from "../../utils/helpers";
import { dataTableModel, renderTableRow } from "../admin-customers/utils";
import { USER_FIELDS } from "../admin-customers/AdminCustomerDetails";
import rgba from "../../assets/theme-dark/functions/rgba";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export function TrafficOverview({width, height}) {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState( {
    labels:  [],
    datasets: [
      {
        label: "Drivers",
        data: [],
        backgroundColor: "cyan",
        borderColor: "white",
        borderRadius: "50",
      },
      {
        label: "Customers",
        data: [],
        backgroundColor: "#0095FE",
        borderColor: "white",
        borderRadius: "50",
      },
    ],
  });

  const getUserTraffic = () => {
    setLoading(true)
    api.getUserTraffic().handle({
      onSuccess: (result) => {
        const { data } = result
        setData({
          labels: data?.map(item => item?.day),
          datasets: [
            {
              label: "Drivers",
              data: data?.map((item) => item?.data?.drivers),
              backgroundColor: "cyan",
              borderColor: "white",
              borderRadius: "50",
            },
            {
              label: "Customers",
              data: data?.map((item) => item?.data?.customers),
              backgroundColor: "#0095FE",
              borderColor: "white",
              borderRadius: "50",
            },
          ],
        })
      },
      errorMessage: 'Error getting user overview',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getUserTraffic()
  }, [])

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0, // Set the minimum value for the y-axis
        max: 100, // Set the maximum value for the y-axis
      },
    },
    pointRadius: 0,
    plugins: {
      title: {
        display: false,
        text: "Traffic Overview",
      },
    },
  };

  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];


  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width}>
      <MDTypography fontWeight={"bold"} fontSize={18}>Traffic Overview</MDTypography>
      <Box mt={2} height={height} width={"100%"}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function CustomersCount({width, height}) {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [newClients, setNewClients] = useState(null);


  const getCustomers = () => {
    setLoading(true)
    api.newCustomers().handle({
      onSuccess: (result) => {
        setNewClients(result.data)
      },
      errorMessage: 'Error getting customers by state',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getCustomers()
  }, [])

  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width} maxHeight={height} sx={{ overflowY: "auto"}}>
      <MDTypography fontWeight={"bold"} fontSize={18} sx={{borderBottom: "1px solid #D9D9D9"}}>Customers</MDTypography>
      <Grid container alignItems={"center"} >
        {newClients?.length > 0 && newClients.map((item, index)=> (
          <React.Fragment key={`${index}`}>
            <Grid item xs={6} md={6}>
              <MDTypography color={"black"}>{item?.state || "Region not specified"}</MDTypography>
            </Grid>
            <Grid item xs={6} md={6}>
              <MDTypography sx={{textAlign: "right"}}>{item?.count}</MDTypography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}

export function MostTimeCustomers({width, height}) {
  const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Horizontal Bar Chart'
      // }
    }
  };

  const labels = ["Homepage", "Delivery Selection", "Checkout", "Payment", "My Orders", "Notifications", ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#0095FE",
      },
    ],
  };
  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width}>
      <MDTypography fontWeight={"bold"} fontSize={18}>Most time spent on screens by Customers</MDTypography>
      <Box mt={2} height={height}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function MostTimeDrivers({width, height}) {
  const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Horizontal Bar Chart'
      // }
    }
  };

  const labels = ["Homepage", "Delivery Selection", "Checkout", "Payment", "My Orders", "Notifications",];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#0095FE",
      },
    ],
  };
  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width}>
      <MDTypography fontWeight={"bold"} fontSize={18}>Most time spent on screens by Drivers</MDTypography>
      <Box mt={2} height={height}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function TotalTimeSpent({width, height}) {
  const options = {
    // responsive: true,
    // maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    // scales: {
    //   x: { ticks: { color: "white" } },
    //   y: {
    //     beginAtZero: true,
    //     min: 0, // Set the minimum value for the y-axis
    //     max: 100, // Set the maximum value for the y-axis
    //     ticks: {
    //       color: "white",
    //       stepSize: 10, // Set the interval between y-axis ticks
    //     },
    //   },
    // },
    //
    // borderColor: "white",
    // pointRadius: 0,
    // plugins: {
    //   legend: {
    //     display: false,
    //   },
    //   title: {
    //     display: true,
    //     text: "Student Understanding",
    //     color: "white",
    //     align: 'start',
    //     font: {
    //       weight: 'bold',
    //       size: 12
    //     },
    //   },
    // },
    // aspectRatio: 1.5,
    legend: {
      display: false,
    },
    tooltip: {
      enable: false
    },
  };

const xx = Math.random() * 100
  const data = {
    labels: [
      'Customers',
      'Drivers',
    ],
    datasets: [{
      data: [xx, 100-xx,],
      backgroundColor: [
        '#0095FE',
        'cyan',
      ],
      hoverOffset: 4
    }]
  };
  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width}>
      <MDTypography fontWeight={"bold"} fontSize={18}>Total Time Spent</MDTypography>
      <MDBox  height={height}>
        <Doughnut options={options} data={data}/>
      </MDBox>
    </Box>
  );
}

export const AgeGroup = ({width, height, onHeightChange}) => {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  // const [calcHeight, setCalcHeight] = useState(0);
  const ref = useRef(null);
  const [data, setData] = useState( {
    labels:  [],
    datasets: [
      {
        label: "Dataset 1",
        data: [],
        backgroundColor: "#0095FE",
      },
    ],
  });


  const getAgeGroup = () => {
    setLoading(true)
    api.ageGroupCustomers().handle({
      onSuccess: (result) => {
        const { data } = result
        setData({
          labels:  data?.map(item => item.age_group || "Age not specified") || [],
          datasets: [
            {
              label: "Dataset 1",
              data: data?.map(item => item.percentage) || [],
              backgroundColor: "#0095FE",
            },
          ],
        })
      },
      errorMessage: 'Error getting age group',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getAgeGroup()
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        // Actualiza el estado con la nueva altura
        // setCalcHeight(entry.contentRect.height);
        if (onHeightChange) onHeightChange(`${entry.target.clientHeight}px`)
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  // Sample data for the chart
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    // responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Horizontal Bar Chart'
      // }
    }
  };

  return (
    <Box ref={ref} borderRadius={2} boxShadow={2} p={2} mb={3} width={width} height={height || 'auto'}>
      <MDTypography fontWeight={"bold"} fontSize={18}>Age Group</MDTypography>
      <Box mt={2} width={"100%"} >
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
};
