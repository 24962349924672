
// @mui icons

import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import ProductOrderListPage from "./pages/dashboard/orderManagements/orders";
import OrderRequests from "./pages/dashboard/orderManagements/orderRequests";
import { AdminFeedback } from "./pages/dashboard/feedback";
import Invitation from "./pages/dashboard/Invitation";
import { PaymentManagement } from "./pages/dashboard/payment";

// Images
import {
  Bell,
  ChartPieSlice,
  UserGear,
  Jeep,
  Package,
  Approve,
  Store,
  Dollar,
  BellIcon,
  MessageIcon,
  SignOut,
} from "assets/theme/components/SvgIcons";
import TuneIcon from '@mui/icons-material/Tune';
import AdminCustomers from "./pages/admin-customers";
import Logout from "./pages/logout";
import AdminDrivers from "./pages/admin-drivers";
import AdminApproveAccounts from "./pages/admin-approve-account";
import AdminStoreManagement from "./pages/admin-store-management";
import AdminNotificationsManagements from "./pages/admin-notifications";
import { ManageVariables } from "./pages/dashboard/manageVariables";
import AdminAnalytics from "./pages/admin-analytics";


const routes = [
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route: "/dashboard/analytics",
    component: <AdminAnalytics/> ,
    noCollapse: true,
    icon: <ChartPieSlice />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    route: "/dashboard/customers",
    component: <AdminCustomers />,
    noCollapse: true,
    icon: <UserGear />,
  },
  {
    type: "collapse",
    name: "Drivers",
    key: "drivers",
    route: "/dashboard/drivers",
    component: <AdminDrivers />,
    noCollapse: true,
    icon: <Jeep />,
  },
  {
    type: "collapse",
    name: "Order Managements",
    // route: "/dashboard/managements",
    key: "orders",
    icon: <Package />,
    collapse: [
      {
        type: "collapse",
        // WIP FOR WARNING CLEANING

        // name: (
        //   <MDTypography sx={{ color: "#fff", fontSize: 14, fontWeight: 700, ml: 2 }}>
        //     <Request sx={{ mr: 2 }} />
        //     Orders
        //   </MDTypography>
        // ),
        name: "Orders",
        key: "orders-orders",
        route: "/managements/orders-orders",
        icon: <MarkunreadMailboxIcon />,
        component: <ProductOrderListPage />,
      },
      {
        type: "collapse",
        // WIP FOR WARNING CLEANING

        // name: (
        //   <MDTypography sx={{ color: "#fff", fontSize: 14, fontWeight: 700, ml: 2 }}>
        //     <OrdersIcon sx={{ mr: 2 }} />
        //     Order Requests
        //   </MDTypography>
        // ),
        name: "Orders requests",
        key: "order-requests",
        route: "/managements/order-requests",
        icon: <MarkunreadMailboxIcon />,
        component: <OrderRequests />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Approve accounts",
    key: "approve-accounts",
    route: "/dashboard/approve-accounts",
    component: <AdminApproveAccounts />,
    noCollapse: true,
    icon: <Approve />,
  },
  {
    type: "collapse",
    name: "Store management",
    key: "store-management",
    route: "/dashboard/store-management",
    component: <AdminStoreManagement />,
    noCollapse: true,
    icon: <Store />,
  },
  {
    type: "collapse",
    name: "Payment management",
    key: "payment-management",
    route: "/dashboard/payment-management",
    component: <PaymentManagement />,
    noCollapse: true,
    icon: <Dollar />,
  },
  {
    type: "collapse",
    name: "Push notifications",
    key: "push-notifications",
    route: "/dashboard/push-notifications",
    component: <AdminNotificationsManagements />,
    noCollapse: true,
    icon: <BellIcon />,
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    route: "/dashboard/feedback",
    component: <AdminFeedback />,
    noCollapse: true,
    icon: <MessageIcon />,
  },
  {
    type: "collapse",
    name: "Send invitation",
    key: "send-invitation",
    route: "/dashboard/send-invitation",
    component: <Invitation />,
    noCollapse: true,
    icon: <Bell />,
  },
  {
    type: "collapse",
    name: "Manage Variables",
    key: "manage",
    route: "/dashboard/manage",
    component: <ManageVariables />,
    noCollapse: true,
    icon: <TuneIcon />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/dashboard/logout",
    component: <Logout />,
    noCollapse: true,
    icon: <SignOut />,
  },
];

export default routes;
