import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import AdminLayout from "../../../../components/AdminLayout";
import { KeepMeForeverLit } from "../../../../assets/theme/components/SvgIcons";

import UpdateQuoteModal from "components/Orders/UpdateQuoteModal";
import DeclineQuoteModal from "components/Orders/DeclineQuoteModal";

export const CUSTOMER_INFO = {
  name: "Customer Name:",
  email: "Customer Email:",
  phone: "Delivery Phone:",
  ownTanks: "Tanks Owned:",
  deliveryFrequency: "Delivery Frequency:",
  preferredDays: "Preferred Day(s):",
  needs: "Needs:",
};

export const ORDER_INFO = {
  needsCabinet: "Need storage cabinet:",
  cabinetSize: "Cabinet size:",
};

function OrderRequestDetails() {
  const [updateQuoteIsOpen, setUpdateQuoteIsOpen] = useState(false);
  const [declineQuoteIsOpen, setDeclineQuoteIsOpen] = useState(false);
  const [formError, setFormError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const order = location?.state?.order;

  // Prepare customer info
  const customerInfo = {};
  customerInfo.name = order?.customer?.name || "-";
  customerInfo.email = order?.customer?.email || "-";
  customerInfo.phone = order?.customer?.phone_number || "-";
  customerInfo.ownTanks = order?.owns_tanks ? "Yes" : "No";
  customerInfo.deliveryFrequency = order?.preferred_delivery_frequency?.display || "-";
  customerInfo.preferredDays =
    order?.preferred_delivery_days.map((day) => day.display).join(", ") || "-";
  customerInfo.needs = order?.more_about || "-";

  // Prepare order info
  const orderInfo = {};
  orderInfo.needsCabinet = order?.needs_cabinet ? "Yes" : "No";
  orderInfo.cabinetSize = order?.preferred_cabinet_size?.display || "-";

  const backButtonHandler = () => {
    navigate(-1);
  };

  const updateModalOpenHandler = () => {
    setUpdateQuoteIsOpen(true);
    setFormError("");
  };

  const updateModalCloseHandler = (goBack) => {
    setFormError("");
    setUpdateQuoteIsOpen(false);
    if (goBack) {
      backButtonHandler();
    }
  };

  const declineModalOpenHandler = () => {
    setDeclineQuoteIsOpen(true);
    setFormError("");
  };

  const declineModalCloseHandler = () => {
    setFormError("");
    setDeclineQuoteIsOpen(false);
    backButtonHandler();
  };

  return (
    <AdminLayout title={"Order Requests"}>
      <MDBox width={"100%"}>
        {/* Sub page header */}
        <MDBox
          display={"flex"}
          alignItems={"center"}
          height={"120px"}
          borderRadius={"10px"}
          justifyContent="space-between"
          mb={5}
          sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <MDBox display={"flex"} alignItems="center">
            <IconButton sx={{ ml: 2 }} onClick={backButtonHandler}>
              <ChevronLeftIcon fontSize={"large"} color="black" />
            </IconButton>
            <KeepMeForeverLit sx={{ ml: 2 }} fontSize={"large"} />
            <MDBox>
              <MDTypography color={"black"} variant={"h3"} ml={2}>
                Order ID: {order?.order_id}
              </MDTypography>
              <MDTypography sx={{ fontSize: "16px", fontWeight: 500 }} ml={2}>
                Keep me Forever Lit
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox display={"flex"} gap={2} mr={2}>
            <MDButton
              variant="contained"
              color="secondary"
              size={"large"}
              sx={{ backgroundColor: "#364861", color: "#fff" }}
              onClick={declineModalOpenHandler}
            >
              Decline Offer
            </MDButton>
            <MDButton variant="contained" color="primary" sx={{}} onClick={updateModalOpenHandler}>
              Update Quote
            </MDButton>
          </MDBox>
        </MDBox>
        {/* End Sub page header */}

        <MDBox display={"flex"} justifyContent={"center"} alignItems={"top"} gap={2}>
          {/* Customer info card */}
          <MDBox
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            width={"50%"}
            p={3}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Customer Information
              </MDTypography>
            </MDBox>
            <Grid container>
              {Object.entries(CUSTOMER_INFO).map(([key, label], index) => (
                <React.Fragment key={`customer-info-key-${index}`}>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid item xs={6} md={6} bgcolor={index - (1 % 2) ? "#f5f5f5" : "white"}>
                    <MDTypography sx={{ textAlign: "right" }}>{customerInfo[key]}</MDTypography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </MDBox>
          {/* End Customer info card */}

          {/* Order info card */}
          <MDBox
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            width={"50%"}
            p={3}
          >
            <MDBox borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Order information
              </MDTypography>
            </MDBox>
            <Grid container>
              {/* Tank transactions list items */}
              {order?.tank_transactions?.length ? (
                <>
                  {order?.tank_transactions.map((trx, trxIndex) => (
                    <React.Fragment key={`transaction-key-${trxIndex}`}>
                      <Grid item xs={6} md={6} bgcolor={trxIndex % 2 ? "#f5f5f5" : "white"}>
                        <MDTypography color={"#364861"}>
                          {trx?.is_exchange ? "Exchange" : "Buy"} Tank Size:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6} md={6} bgcolor={trxIndex % 2 ? "#f5f5f5" : "white"}>
                        <MDTypography sx={{ textAlign: "right" }}>
                          {trx?.size?.name || "-"}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6} md={6} bgcolor={trxIndex % 2 ? "#f5f5f5" : "white"}>
                        <MDTypography color={"#364861"}>Number of Tanks:</MDTypography>
                      </Grid>
                      <Grid item xs={6} md={6} bgcolor={trxIndex % 2 ? "#f5f5f5" : "white"}>
                        <MDTypography sx={{ textAlign: "right" }}>
                          {trx?.quantity || "-"}
                        </MDTypography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <Grid item xs={6} md={6} bgcolor={1 % 2 ? "#f5f5f5" : "white"}>
                    <MDTypography color={"#364861"}>
                      This order has no tank transactions
                    </MDTypography>
                  </Grid>
                </>
              )}
              {/* End Tank transactions list items */}

              <Divider />

              {Object.entries(ORDER_INFO).map(([key, label], index) => (
                <React.Fragment key={`customer-info-key-${index}`}>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "white" : "#f5f5f5"}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "white" : "#f5f5f5"}>
                    <MDTypography sx={{ textAlign: "right" }}>{orderInfo[key]}</MDTypography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </MDBox>
          {/* End Order info card */}
        </MDBox>
      </MDBox>

      <UpdateQuoteModal
        order={order}
        formError={formError}
        updateFormError={setFormError}
        isOpen={updateQuoteIsOpen}
        onClose={updateModalCloseHandler}
      />

      <DeclineQuoteModal
        order={order}
        formError={formError}
        updateFormError={setFormError}
        isOpen={declineQuoteIsOpen}
        onClose={declineModalCloseHandler}
      />
    </AdminLayout>
  );
}

export default OrderRequestDetails;
