import { React, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import { observer } from "mobx-react";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import FormikInput from "../../../components/FormikInput";
import AdminLayout from "../../../components/AdminLayout";
import Card from "@mui/material/Card";
import { useApi } from "services/helpers";
import { USER_TYPE } from "../../../utils/helpers";

function Invitation() {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const sendInvitation = (data) => {
    console.log(data)
    setLoading(true)
    api.sendInvitation({...data, name: data.first_name, type: USER_TYPE.DRIVER}).handle({
        successMessage: 'Driver invited',
        errorMessage: 'Error inviting driver',
        onSuccess: () => formik.resetForm(),
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required field"),
    first_name: Yup.string().required("First name is a required field"),
    phone_number: Yup.string().required("Contact number is a required field"),
    password: Yup.string().required("Temporary password is a required field"),
  });

  const initialValues = {
    email: "",
    first_name: "",
    phone_number: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => sendInvitation(values),
    enableReinitialize: true,
  });


  return (
    <AdminLayout title={"Send Invitation"}>
      <Card sx={{p: 4, width: "100%" }}>
        <MDTypography sx={{ fontSize: "24xp", fontWeight: 700, mb: 4 }}>Send Invitation</MDTypography>
        <MDBox>
          <FormikProvider value={formik}>
            <MDBox>
              <FormikInput
                name={"first_name"}
                label={"First Name"}
                placeholder={"Input the first name"}
                type={"textarea"}
                mb={2}
                errors={formik.errors}
              />

              <FormikInput
                name={"email"}
                label={"Email"}
                placeholder={"Input the email"}
                type={"email"}
                mb={2}
                errors={formik.errors}
              />
              <FormikInput
                name={"phone_number"}
                label={"Phone Number "}
                placeholder={"input the phone number"}
                type={"phone_input"}
                mb={1}
                errors={formik.errors}
                sx={{flexGrow: 1}}
              />
              <FormikInput
                name={"password"}
                label={"Temporary Password"}
                placeholder={"Input Temporary Password..."}
                errors={formik.errors}
                type={"password"}
                mb={2}

              />
                <MDButton
                  variant="contained"
                  color="primary"
                  size={"large"}
                  onClick={formik.handleSubmit}
                  loading={loading}
                  sx={{ maxWidth: '350px',
                  fontSize:{xs:'10px',sm:'14px'}}}
                >
                  Create Account and Send Invite
                </MDButton>
            </MDBox>
          </FormikProvider>
        </MDBox>
      </Card>
    </AdminLayout>
  );
}

export default observer(Invitation);
