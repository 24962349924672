import pxToRem from "assets/theme/functions/pxToRem";

export const Container = ({palette: {white}}) => ({
  backgroundColor: white.main,
  borderRadius: '16px',
  display: "flex",
  justifyContent: "space-between",
  height: 'auto',
  marginBottom: 5,
  p: "14px 14px 14px 0",
  alignItems: "center",
  '@media screen and (max-width: 899px)': {
    flexDirection:'column',
    // minWidth:'415px'

  }

})

export const FilterItem = ({palette: {white}}) => ({

  display:'flex',
  width:"100%",
  mr:'15px',
  '@media screen and (max-width: 899px)': {
  justifyContent:'space-between',}

})

export const FilterItemModified = (theme) => ({
  borderBottomLeftRadius: pxToRem(16),
  borderTopLeftRadius: pxToRem(16),
  display: {xs: 'none', md: 'inherit'},
  flex: 0,
  paddingInline: '4px',
  paddingX: '10px',
  width:"100%"
})

export const FilterText = () => ({
  fontSize: pxToRem(14),
  fontWeight: 500,
  lineHeight: pxToRem(17),
})

export const ArrowIcon = ({palette: {icons}}) => ({
  color: icons.arrow_icon,
  height: pxToRem(30),
  width: pxToRem(30),
})

export const InputContainer = () => ({
  display: "flex",
  justifyContent: "flex-start",
  width:"100%",
  flexWrap:'wrap',
  '@media screen and (max-width: 899px)': {
    justifyContent:'space-between',
    width:'100%'

  }
})

export const Input = ({palette: {table}}) => ({
  display:"flex",
  // border: `1px solid ${table.border}`,
  // borderRadius: pxToRem(8),
  width:"100%",


})
