/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "App";
import './index.css';

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

import StoreApp from "./StoreApp";

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://538700c74d9941d5b1dc360c9911e2e1@sentry.innovatica.com.py//51",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["0.0.0.0", "localhost", /^https:\/\/keep-calm-ars-app-38443\.botics\.co\/api/],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //  <React.StrictMode>
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <StoreApp>
        <App/>
      </StoreApp>
    </MaterialUIControllerProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
