/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import logo from "assets/images/logos/logo.png";
import PageLayout from "../PageLayout";
import pxToRem from "../../assets/theme/functions/pxToRem";
import arrow_left from "assets/icons/CaretLeftActivated.svg";
import { Link, useNavigate } from "react-router-dom";
import bgImage from "../../assets/images/bgColor.svg";
import Container from "@mui/material/Container";
import { Form, Formik } from "formik";
import gas from "../../assets/images/g10.svg";
import FormikInput from "../FormikInput";
import { ROUTES } from "../../services/constants";
import MDButton from "../MDButton";

function IllustrationLayout({ header, title, description, illustration, children, goBackOption=false }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate()

  return (
    <MDBox sx={{
      overflowX: "hidden",
      overflowY: "hidden",
      justifyItems: "center",

    }}>
      <MDBox
        style={{
          height: "100%",
          width: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: '#fff',
          position: "absolute",
          zIndex: -1,
        }}
      >
        <img alt="side" src={bgImage} style={{ width: "100%", objectFit: "cover"}} />
      </MDBox>

      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          maxHeight="90vh"
          p={4}
          borderRadius="xl"
          sx={{
            width:{xs:'100%', sm:'442px'},
            margin: "auto",
            alignConten:'center',
            justifyContent:'center',
            display:'flex',
            flexDirection:'column'
          }}
        >
          <MDBox sx={{ marginBottom: {xs:10, md:30 }}}>
            <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pb: 2, gap: 3 }}>
              <MDBox component={'img'} src={gas} alt={'gas-img'} />
              <MDTypography
                variant="h3"
                color="#0095FE"
                fontWeight="bold"
              >
                {title}
              </MDTypography>
              <MDTypography
                variant="h6"
                color="white"
                fontWeight="bold"
              >
                {description}
              </MDTypography>
              </MDBox>
              {children}
            </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
