import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../services/helpers";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import warningIcon from "../../assets/icons/warningCircle.svg";
import prohibitIcon from "../../assets/icons/Prohibit.svg";
import trashIcon from "../../assets/icons/TrashSimple.svg";
import { Grid } from "@mui/material";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { ROUTES } from "../../services/constants";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import reactive from "../../assets/icons/reactiveUser.svg";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import pxToRem from "../../assets/theme/functions/pxToRem";
import dayjs from 'dayjs';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function AdminAddNewNotification() {
  const api = useApi();
  const navigate = useNavigate();
  const { notificationId } = useParams();
  const { state } = useLocation();
  const formikRef = useRef();
  const [modalName, setModalName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);


  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  const createNotification = (data) => {
    setLoading(true);
    api.createNotification(data).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_PUSH_NOTIFICATION);
      },
      successMessage: "Notification created successfully",
      errorMessage: "Error creating notification",
      onError: err => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const updateNotification = (data) => {
    setLoading(true);
    api.updateNotification(notificationId, data).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_PUSH_NOTIFICATION);
      },
      successMessage: "Notification updated successfully",
      errorMessage: "Error updating notification",
      onError: err => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };


  const validationSchema =
    Yup.object().shape({
      title: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      sent_timestamp: Yup.string().required("Required"),
      send_to: Yup.mixed().required("Required"),
    });

  const initialValues = {
    title: state?.title || "",
    description: state?.description || "",
    sent_timestamp: state?.sent_timestamp || "",
    send_to: [], // Inicializar send_to como un array vacío si no hay datos
  };


  const getOptionLabel = (option) => {
    switch (option) {
      case 1:
        return "Customers";
      case 2:
        return "Company Drivers";
      case 3:
        return "Regular Drivers";
      default:
        return "";
    }
  };

  return (
    <AdminLayout title={"Push Notifications"}>
      <MDBox display={"flex"} alingitems={"start"} width={"100%"}>
        <MDBox width={{xs:"100%",md:'60%'}}>
          <MDBox
            borderRadius={"10px"}
            p={3}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <MDTypography color={"black"} variant={"h3"} mb={4}>
              Create new notification
            </MDTypography>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldValue }) => {
              const formattedData = {
                title: values.title,
                description: values.description,
                send_to: values.send_to || [],  // Asegúrate de tener un array
                scheduled_datetime: values.sent_timestamp || "",  // Ajusta según sea necesario
              };

              if (notificationId) {
                updateNotification(formattedData);
              } else {
                createNotification(formattedData);
              }
            }}
          >
            {({ errors, isValid, dirty,  setFieldValue, values}) =>
            {
              // console.log(values)
              return (
              <Form>
                <MDBox>
                  <FormikInput
                  name={"title"}
                  label={"Title"}
                  errors={errors}
                  mb={2}
                  />
                  <FormikInput
                  name={"description"}
                  label={"Description"}
                  errors={errors}
                  mb={2}

                  />
                  <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={usersType}
                      disableCloseOnSelect
                      value={values.send_to || []}
                      getOptionLabel={(option) => {
                        switch (option) {
                          case 1:
                            return "Customers";
                          case 2:
                            return "Company Drivers";
                          case 3:
                            return "Regular Drivers";
                          default:
                            return "";
                        }
                      }}
                      onChange={(event, newValue) => {
                        setFieldValue("send_to", newValue);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {getOptionLabel(option)}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Sent to" placeholder="select" />
                      )}
                    />

                    <FormikInput
                      name={"sent_timestamp"}
                      label={"Start Time"}
                      type={"datetime"}
                      placeholder={"Input your time"}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      sx={{flexGrow: 1}}
                      mt={2}
                    />

                  </MDBox>
                  <MDButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    disabled={loading || !isValid || !dirty}
                    sx={{width: pxToRem(280)}}
                    type='submit'
                  >
                    Send
                  </MDButton>
                </MDBox>
              </Form>
              )}}
          </Formik>
          </MDBox>
        </MDBox>
      </MDBox>

    </AdminLayout>
  );
}

export default AdminAddNewNotification;

const usersType = [1, 2, 3];
