/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import * as Yup from "yup";

// Image
import bgImage from "assets/images/bgColor.svg";
import gas from "assets/images/g10.svg";
import IllustrationLayout from "../../components/IllustrationLayout";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {ROLES, ROUTES} from "../../services/constants";
import {observer} from "mobx-react";
import {useStores} from "../../models";
import {useApi} from "../../services/helpers";
import {useRef, useState, useEffect} from "react";
import {runInAction} from "mobx";
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import {setLayout} from "../../context";
import Container from "@mui/material/Container";

function SignIn() {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const navigate = useNavigate()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [hasLogin, setHasLogin] = useState(false);

  const login = (data) => {
    setLoading(true)
    api.login(data.email, data.password).handle({
        onSuccess: (result) => {
          const {response} = result
          const {user, access} = response

          runInAction(() => {
            loginStore.setUser(user)
            loginStore.setApiToken(access)
          })

          // getLanguages()
          navigate(ROUTES.ADMIN_ANALYTICS)
          // setHasLogin(true)
        },
        errorMessage: 'Error signing in',
        onFinally: () => setLoading(false)
      }
    )
  }

  // const getLanguages = () => {
  //   api.getLanguages().handle({
  //       onSuccess: (result) => {
  //         const {data} = result
  //         runInAction(() => {
  //           loginStore.setLanguages(data.map(language => {return {id: language.id, value: language.code, label: language.name}}))
  //         })
  //       },
  //       errorMessage: 'Error getting languages',
  //     }
  //   )
  // }


  const validationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })

  const initialValues = {
    email: "",
    password: "",
  };

  return (

    <IllustrationLayout
      title="Sign In"
      description=""
      illustration={bgImage}
      goBackOption={true}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={values => {
          login(values);
        }}
      >
        {({errors, isValid}) => (
          <Form>
            <MDBox sx={{ boxShadow: 2, py: 6, px: 5, borderRadius: 2, backgroundColor: '#fff' }}>
              <FormikInput name={'email'} label={'Email'} type={'email'} errors={errors} mb={4}/>
              <FormikInput name={'password'} label={'Password'} type={'password'} errors={errors}/>
              <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'  }}>
                <FormikInput type={'checkbox'} label={'Remember me'} />
              </MDBox>
            </MDBox>
            <MDBox textAlign="right">
              <MDTypography variant="button">
                <MDTypography
                  component={Link}
                  to={ROUTES.FORGOT_PASSWORD}
                  variant="button"
                  color="primary"
                  fontWeight="regular"
                >
                  Forgot Password?
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign={"center"}>
              <MDButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading || !isValid}
                sx={{width: pxToRem(280)}}
                type='submit'
              >
                Sign In
              </MDButton>
            </MDBox>
          </Form>
        )}
      </Formik>
    </IllustrationLayout>
      // <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#fff', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat' }}>
      //   <Formik
      //     innerRef={formikRef}
      //     initialValues={initialValues}
      //     validationSchema={validationSchema}
      //     validateOnBlur={false}
      //     onSubmit={values => {
      //       login(values);
      //     }}
      //   >
      //     {({errors, isValid}) => (
      //       <Form>
      //         <MDBox sx={{ marginBottom: 30 }}>
      //           <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pb: 2, gap: 3 }}>
      //             <MDBox component={'img'} src={gas} alt={'gas-img'} />
      //             <MDTypography
      //               variant="h3"
      //               color="white"
      //               fontWeight="bold"
      //             >
      //               Sign In
      //             </MDTypography>
      //           </MDBox>
      //           <MDBox sx={{ boxShadow: 2, py: 6, px: 10, borderRadius: 2, backgroundColor: '#fff' }}>
      //             <FormikInput name={'email'} label={'Email'} type={'email'} errors={errors} mb={4}/>
      //             <FormikInput name={'password'} label={'Password'} type={'password'} errors={errors}/>
      //             <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'  }}>
      //               <FormikInput type={'checkbox'} label={'Remember me'} />
      //             </MDBox>
      //           </MDBox>
      //           <MDBox textAlign="right">
      //             <MDTypography variant="button">
      //               <MDTypography
      //                 component={Link}
      //                 to={ROUTES.FORGOT_PASSWORD}
      //                 variant="button"
      //                 color="primary"
      //                 fontWeight="regular"
      //               >
      //                 Forgot Password?
      //               </MDTypography>
      //             </MDTypography>
      //           </MDBox>
      //           <MDBox mt={3} mb={1} textAlign={"center"}>
      //             <MDButton
      //               variant="contained"
      //               color="primary"
      //               loading={loading}
      //               disabled={loading || !isValid}
      //               sx={{width: pxToRem(280)}}
      //               type='submit'
      //             >
      //               Sign In
      //             </MDButton>
      //           </MDBox>
      //         </MDBox>
      //       </Form>
      //     )}
      //   </Formik>
      // </MDBox>
  );
}

export default observer(SignIn);
