import React, { useRef } from "react";
import { Form, Formik } from "formik";
import { Typography } from "@mui/material";

import { wrapApiCall } from "services/helpers_ts";
import { showMessage } from "services/helpers";
import { apiMessages } from "services/api/api-messages";
import { useApi } from "services/helpers";
import { ORDER_TYPE } from "utils/helpers";

import { BaseModal } from "components/BaseModal";
import MDBox from "components/MDBox";
import QuoteCardHeader from "./QuoteCardHeader";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

const DeclineQuoteModal = ({ order, formError, updateFormError, isOpen, onClose }) => {
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const cancelFormkRef = useRef();
  const api = useApi();

  const closeConfirmHandler = () => {
    setConfirmIsOpen(false);
  };

  const cancelQuoteHandler = () => {
    const values = cancelFormkRef.current?.values;
    const hasMessage = !!values?.message.trim();
    if (!hasMessage) {
      updateFormError("Please complete the form");
      return;
    } else {
      updateFormError("");
    }
    setConfirmIsOpen(true);
  };

  const submitConfirmHandler = () => {
    const values = cancelFormkRef.current?.values;

    const orderId = order?.id;
    const updatedOrder = {
      admin_message: values?.message || "",
      status: ORDER_TYPE.CANCELLED,
    };

    wrapApiCall(api.updateCustomOffer(orderId, updatedOrder), {
      onSuccess: (_result) => {
        showMessage(apiMessages.update.success("Decline order quote"), "success");
        onClose();
      },
      onError: (_error) => {
        showMessage(apiMessages.update.error("Decline order quote"), "error");
      },
      onFinally: () => {
        setLoading(false);
        closeConfirmHandler();
      },
    });
  };

  return (
    <>
      <BaseModal open={isOpen} handleClose={onClose} height="540px">
        <MDBox display="flex" flexDirection="column" alignItems="center" gap={4} m={4}>
          <Typography variant="h3" color="#000">
            Decline Offer
          </Typography>
          <QuoteCardHeader
            orderId={order?.order_id}
            name={order?.customer?.name}
            email={order?.customer?.email}
            phoneNumber={order?.customer?.phone_number}
          />
          {/* Pricing form */}
          <MDBox display="flex" flexDirection="column" width="100%">
            <Formik
              innerRef={cancelFormkRef}
              initialValues={{ message: "" }}
              onSubmit={cancelQuoteHandler}
            >
              {({ errors }) => (
                <Form>
                  <MDBox display="flex" alignItems="center">
                    <FormikInput
                      name={"message"}
                      label="Message"
                      errors={errors}
                      type="textarea"
                      multiline={true}
                      rows={4}
                      sx={{ width: "100%" }}
                    />
                  </MDBox>
                  <Typography color="error" sx={{ marginBottom: 2, marginTop: 0 }} fontSize={14}>
                    {formError}
                  </Typography>

                  <MDButton variant="contained" color="primary" fullWidth type="submit">
                    Send
                  </MDButton>
                </Form>
              )}
            </Formik>
          </MDBox>
          {/* End Pricing form */}
        </MDBox>
      </BaseModal>

      {confirmIsOpen && (
        <ConfirmModal
          title="Are you sure that you want to Decline this Quote?"
          open={confirmIsOpen}
          handleClose={closeConfirmHandler}
          handleConfirm={submitConfirmHandler}
          loading={loading}
        />
      )}
    </>
  );
};

export default DeclineQuoteModal;
