import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import { useApi } from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import { dataTableModel, renderTableRow } from "./utils";
import DataTable from "../../components/DataTable";
import { useNavigate } from "react-router-dom";
import { DRIVER_STATUS, MODAL_NAME, USER_TYPE } from "../../utils/helpers";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";


function AdminApproveAccounts() {
  const api = useApi()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [modalName, setModalName] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)
  const [table, setTable] = useState(dataTableModel)
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });

  const getFilteredPage = () =>{
    let filters =  {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  const getAllUsers = (search = '', page = 1, ordering = paginationData.ordering) => {
    setLoading(true)
    api.getAllUsers({search, page, page_size: pageSize, ordering, user_type: USER_TYPE.DRIVER, driver_status: DRIVER_STATUS.PENDING}).handle({
        onSuccess: (result) => {
          const {count, start, end, results} = result?.data
          const tmp = {...dataTableModel}
          tmp.rows = results.map(e => renderTableRow(e, setOpenModal, setSelectedItem, navigate, setModalName))
          setTable(tmp);
          setPaginationData({
            counts: count,
            start,
            end,
            itemsPerPage: results.length,
            currentPage: page,
            ordering
          })
        },
        errorMessage: 'Error getting users',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const blockUser = () => {
    setLoading(true)
    api.blockUser(selectedItem.id).handle({
        onSuccess: () => {
          setOpenModal(false)
          getAllUsers()
        },
        errorMessage: 'Error deactivating user',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const reactivateUser = () => {
    setLoading(true)
    api.reactivateUser(selectedItem.id).handle({
        onSuccess: () => {
          setOpenModal(false)
          getAllUsers()
        },
        errorMessage: 'Error reactivating user',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const deleteAccount = () => {
    setLoading(true)
    api.deleteAccount(selectedItem.id).handle({
        onSuccess: () => {
          setOpenModal(false)
          getAllUsers()
        },
        errorMessage: 'Error deactivating user',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const handleConfirmModal = () => {
    if (modalName===MODAL_NAME.CANCEL_MODAL) blockUser()
    else if (modalName===MODAL_NAME.DELETE_MODAL) deleteAccount()
    else if (modalName===MODAL_NAME.REACTIVE_MODAL) reactivateUser()
  }

  useEffect(() => {
    const {search, page} = getFilteredPage()
    getAllUsers(search, page)
  }, [searchQuery, pageSize])


  return (
    <AdminLayout
      title={'Approve Accounts'}
    >
      <MDBox width={"100%"}>
        <MDBox width={"100%"} alignItems={"center"} mb={"10px"}>
          <SearchBar placeholder={"Search drivers"} loading={loading} search={getAllUsers} setSearchQuery={setSearchQuery}/>
        </MDBox>
        <MDBox>
          <DataTable
            searchFunc={getAllUsers}
            table={table}
            searchQuery={searchQuery}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              const {search} = getFilteredPage()
              getAllUsers(search, page)
              setPaginationData(value => ({...value, currentPage: page}))
            }}
          />
        </MDBox>
      </MDBox>
      <ConfirmModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleConfirmModal}
        title={`${modalName} user?`}
        subtitle={`Are you sure you want to ${modalName.toLowerCase()} ${selectedItem?.name}?`}
      />
    </AdminLayout>
  );
}

export default AdminApproveAccounts;
