import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useApi } from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useNavigate } from "react-router-dom";
import { DRIVER_STATUS, MODAL_NAME, USER_TYPE } from "../../utils/helpers";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { dataTableModel, renderTableRow } from "./utils";
import { ROUTES } from "../../services/constants";


function AdminNotificationsManagements() {
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [table, setTable] = useState(dataTableModel);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });

  const getNotificationsAdmin = (page = 1, ordering = paginationData.ordering) => {
    setLoading(true);
    console.log(page);
    // api.getNotificationsAdmin({ page, pageSize, ordering }).handle({
    api.getNotificationsAdmin({  }).handle({
      onSuccess: (result) => {
        const { count, start, end, results } = result?.data;
        const tmp = { ...dataTableModel };
        console.log(results)
        tmp.rows = results?.map(e => renderTableRow(e, setOpenModal, setSelectedItem, navigate));
        setTable(tmp);
        setPaginationData({
          counts: count,
          start,
          end,
          itemsPerPage: results.length,
          currentPage: page,
          ordering,
        })
      },
      errorMessage: 'Error getting notifications',
      onError: err => {
        console.log(err);
      },
      onFinally: () => setLoading(false)
    });
  };


  const deleteNotification = () => {
    setLoading(true)
    api.deleteNotification(selectedItem?.id).handle({
      onSuccess: (result) => {
        setOpenModal(false)
        getNotificationsAdmin()
      },
      successMessage: 'Notification deleted successfully',
      errorMessage: 'Error deleting notification',
      onError: err => {
        console.log(err);
      },
      onFinally: () => setLoading(false)
    })
  }

  const handleDelete = () => {
    if (selectedItem?.id) deleteNotification()
  }


  useEffect(() => {
    getNotificationsAdmin()
  }, [pageSize]);


  return (
    <AdminLayout title={"Push Notifications"}>
      <MDBox width={"100%"}>
        <MDBox>
          <MDBox
          p={2}
          borderRadius={"10px"}
          mb={5}
          sx={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}
          >
           <MDBox
           display={"flex"}
           justifyContent={"space-between"}
           alingItems={"center"}
           mb={1}
           >
             <MDTypography sx={{fontSize: "24px", fontWeight: 700}}>Existing notifications</MDTypography>
              <MDButton
                color={"primary"}
                sx={{borderRadius: "20px",
                fontSize:{xs:'10px', sm:'14px'}, paddingX:{xs:'12px', md:'24px'}, minWidth:'25px'}}
                onClick={() => navigate(ROUTES.ADMIN_NEW_NOTIFICATION)}
              >
                + Add new notification
              </MDButton>
           </MDBox>
            <DataTable
              searchFunc={getNotificationsAdmin}
              table={table}
              searchQuery={""}
              currentPage={paginationData.currentPage}
              numberOfItems={paginationData.count}
              numberOfItemsPage={paginationData.itemsPerPage}
              start={paginationData.start}
              end={paginationData.end}
              setPageSize={setPageSize}
              pageSize={pageSize}
              onPageChange={page => {
                getNotificationsAdmin(page)
                setPaginationData(value => ({...paginationData, currentPage: page}))
              }}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <ConfirmModal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      handleConfirm={handleDelete}
      title={`Delete notification`}
      subtitle={`Are you sure you want to delete this notification?`}
      />
    </AdminLayout>
  );
}

export default AdminNotificationsManagements;