import { Box, Modal } from "@mui/material";

import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import { useApi } from "../../../../services/helpers";
import { useEffect, useState } from "react";
import she from "../../../../assets/icons/she.png";
import rgba from "../../../../assets/theme-dark/functions/rgba";


function DriverModal({ handleClose, open, handleConfirm }) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);


  const getDrivers = () => {
    setLoading(true)
    api.getDrivers({is_company_driver: true}).handle({
        onSuccess: (result) => {
          console.log(result.data.results)
          setDrivers(result.data.results)
        },
        errorMessage: 'Error getting drivers',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  useEffect(() => {
    if(open) getDrivers()
  }, [open])


  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#fff",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
          width: 500,
          boxShadow: 24,
        }}
      >
        <MDBox display={"flex"} alignItems={"center"} justifyContent={'space-between'} px={2}>
          <MDBox sx={{  width: '100px' }} />
          <MDTypography pt={2} mb={2} fontSize={"22px"}>
            Drivers
          </MDTypography>
          <MDButton
            color={"info"}
            sx={{ borderRadius: "20px", width: '100px' }}
            onClick={() => handleConfirm(selectedDriver?.id)}
            loading={loading}
          >
            Assign
          </MDButton>
        </MDBox>

        <MDBox gap={1} sx={{borderTop: "3px solid rgba(217, 217, 217, 1)"}} mb={3}>
          {console.log( 'drivers => ', drivers)}
          {drivers?.length > 0
            ? drivers.map(driver => (
              <MDBox
                display={"flex"}
                mt={1} mx={2}
                onClick={()=>{setSelectedDriver(driver)}}
                sx={{
                  cursor: "pointer",
                  backgroundColor: selectedDriver?.id===driver.id? "#F3F3F3": "white",
              }}
              >
                <Box component={"img"}
                   src={driver?.profile_picture ? driver.profile_picture : she} alt={"guy"}
                   borderRadius={"50%"} width={62} height={62} ml={1}
                />
                <MDBox ml={2}>
                  <MDTypography sx={{fontSize: "20px", fontWeight: 700}}>
                    {driver?.name}
                  </MDTypography>
                  <MDTypography sx={{color: "#0095FE", fontSize: "12px"}}>
                    {driver?.is_company_driver ? 'Company Driver' : ""}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ))
            : <MDTypography sx={{color: "#aaa", fontSize: "25px", textAlign: 'center', marginTop: 3}}>
            No Drivers Found
          </MDTypography>
          }
        </MDBox>
      </MDBox>
    </Modal>
  );
}

export default DriverModal;
