import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import { useApi } from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { dataTableTankModel, renderTableTankRow, dataTableStoreModel, renderTableStoreRow } from "./utils";
import { ROUTES } from "../../services/constants";


function AdminStoreManagement() {
  const api = useApi()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [tableTanks, setTableTanks] = useState(dataTableTankModel)
  const [tableStores, setTableStores] = useState(dataTableStoreModel)
  const [pageSizeTanks, setPageSizeTanks] = useState(10);
  const [pageSizeStore, setPageSizeStore] = useState(10);
  const [paginationDataTank, setPaginationDataTank] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [paginationDataStore, setPaginationDataStore] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });

  const getTanks = (search="", page = 1, ordering = paginationDataTank.ordering) => {
    setLoading(true)
    api.getTanks({search, page, page_size: pageSizeTanks, ordering,}).handle({
      onSuccess: (result) => {
        const {count, start, end, results} = result?.data
        const tmp = {...dataTableTankModel}
        tmp.rows = results?.map(e => renderTableTankRow(e, setOpenModal, setSelectedItem, navigate))
        setTableTanks(tmp);
        setPaginationDataTank({
          counts: count,
          start,
          end,
          itemsPerPage: results.length,
          currentPage: page,
          ordering
        })
      },
      errorMessage: 'Error getting tanks',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const deleteTank = () => {
    setLoading(true)
    api.deleteTank(selectedItem?.id).handle({
      onSuccess: (result) => {
        setOpenModal(false)
        getTanks()
      },
      successMessage: 'Tank deleted successfully',
      errorMessage: 'Error deleting tank',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const getStores = (search="", page = 1, ordering = paginationDataStore.ordering) => {
    setLoading(true)
    api.getStores({search, page, page_size: pageSizeStore, ordering,}).handle({
      onSuccess: (result) => {
        const {count, start, end, results} = result?.data
        const tmp = {...dataTableStoreModel}
        tmp.rows = results?.map(e => renderTableStoreRow(e, setOpenModal, setSelectedItem, navigate))
        setTableStores(tmp);
        setPaginationDataStore({
          counts: count,
          start,
          end,
          itemsPerPage: results.length,
          currentPage: page,
          ordering
        })
      },
      errorMessage: 'Error getting stores',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const deleteStore = () => {
    setLoading(true)
    api.deleteStore(selectedItem?.id).handle({
      onSuccess: (result) => {
        setOpenModal(false)
        getStores()
      },
      successMessage: 'Store deleted successfully',
      errorMessage: 'Error deleting store',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const handleDelete = () => {
    if (selectedItem?.tank) deleteTank()
    else if (selectedItem?.name) deleteStore()
  }

  useEffect(() => {
    getTanks()
  }, [ pageSizeTanks])
  useEffect(() => {
    getStores()
  }, [ pageSizeStore])


  return (
    <AdminLayout
      title={'Store Management'}
    >
      <MDBox width={"100%"}>
        <MDBox>
          <MDBox width={"100%"}>
            <MDBox
              p={2}
              borderRadius={"10px"}
              mb={5}
              sx={{boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)"}}
            >
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={1}
              >
                <MDTypography>Tank Selection</MDTypography>
                <MDButton
                  color={"primary"}
                  sx={{borderRadius: "20px"}}
                  onClick={() => navigate(ROUTES.ADMIN_ADD_TANK)}
                >
                  + Add New Tank
                </MDButton>
              </MDBox>

              <DataTable
                searchFunc={getTanks}
                table={tableTanks}
                searchQuery={""}
                currentPage={paginationDataTank.currentPage}
                numberOfItems={paginationDataTank.counts}
                numberOfItemsPage={paginationDataTank.itemsPerPage}
                start={paginationDataTank.start}
                end={paginationDataTank.end}
                setPageSize={setPageSizeTanks}
                pageSize={pageSizeTanks}
                onPageChange={page => {
                  getTanks("", page)
                  setPaginationDataTank(value => ({...value, currentPage: page}))
                }}
              />
            </MDBox>

            <MDBox
              p={2}
              borderRadius={"10px"}
              sx={{boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)"}}
            >
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={1}
              >
                <MDTypography>Store Selection</MDTypography>
                <MDButton
                  color={"primary"}
                  sx={{borderRadius: "20px"}}
                  onClick={() => navigate(ROUTES.ADMIN_ADD_STORE)}
                >
                  + Add New Store
                </MDButton>
              </MDBox>
              <DataTable
                searchFunc={getStores}
                table={tableStores}
                searchQuery={""}
                currentPage={paginationDataStore.currentPage}
                numberOfItems={paginationDataStore.counts}
                numberOfItemsPage={paginationDataStore.itemsPerPage}
                start={paginationDataStore.start}
                end={paginationDataStore.end}
                setPageSize={setPageSizeStore}
                pageSize={pageSizeStore}
                onPageChange={page => {
                  getStores("", page)
                  setPaginationDataStore(value => ({...value, currentPage: page}))
                }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <ConfirmModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleDelete}
        title={`Delete ${selectedItem?.tank? "tank" : "store" }?`}
        subtitle={`Are you sure you want to delete ${selectedItem?.tank ||  selectedItem?.name}?`}
      />
    </AdminLayout>
  );
}

export default AdminStoreManagement;
