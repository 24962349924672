import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../services/helpers";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import warningIcon from "../../assets/icons/warningCircle.svg"
import prohibitIcon from "../../assets/icons/Prohibit.svg"
import trashIcon from "../../assets/icons/TrashSimple.svg"
import { Grid } from "@mui/material";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { ROUTES } from "../../services/constants";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import reactive from "../../assets/icons/reactiveUser.svg";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import pxToRem from "../../assets/theme/functions/pxToRem";

function AdminStoreDetail() {
  const api = useApi()
  const navigate = useNavigate();
  const { storeId } = useParams();
  const { state } = useLocation();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const createStore = (data) => {
    let values = {...data}
    values.point = `POINT(${data.longitude} ${data.latitude})`

    setLoading(true)
    api.createStore(values).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_STORE_MANAGEMENT)
      },
      successMessage: 'Store created successfully',
      errorMessage: 'Error creating store',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const updateStore = (data) => {
    setLoading(true)
    api.updateStore(storeId, data).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_STORE_MANAGEMENT)
      },
      successMessage: 'Store updated successfully',
      errorMessage: 'Error updating store',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const validationSchema =
    Yup.object().shape({
      name: Yup.string().required(),
      address: Yup.string().required(),
      capacity: Yup.number().min(0).required(),
      latitude: Yup.number().required('latitude is a required field'),
      longitude: Yup.number().required('longitude is a required field'),
      additional_info: Yup.string().required('additional info is a required field'),
    })

  const initialValues = {
    name: state?.name || "",
    address: state?.address || "",
    capacity: state?.capacity || '',
    additional_info: state?.additional_info || "",
    latitude: state?.point?.[1] || '',
    longitude: state?.point?.[0] || '',
  };

  return (
    <AdminLayout
      title={'Store Management'}
    >
      <MDBox display={'flex'} alingItems={'start'} width={"100%"}>
        <MDBox width={{xs:"100%", sm:"60%"}}>
          <MDBox
            borderRadius={"10px"}
            p={3}
            sx={{boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)"}}
          >
            <MDTypography color={"black"} variant={"h3"} mb={4}>
              Add new Store
            </MDTypography>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              enableReinitialize
              onSubmit={values => {
                if (storeId) updateStore(values)
                else createStore(values);
              }}
            >
              {({errors, isValid, dirty}) => (
                <Form>
                  {console.log(' => ', state)}
                  <MDBox display={"flex"} flexDirection={"column"} gap={3}>
                    <FormikInput name={'name'} label={'Store Name'} errors={errors}/>
                    <FormikInput name={'address'} label={'Address'} errors={errors}/>
                    <FormikInput name={'capacity'} label={'Tank Storage Capacity'} type={'number'} errors={errors} width={"100%"}/>
                    <MDBox display={'flex'} gap={3}>
                    <FormikInput name={'latitude'} label={'Latitude'} type={'number'} errors={errors} width={"50%"}/>
                    <FormikInput name={'longitude'} label={'Longitude'} type={'number'} errors={errors} width={"50%"}/>
                    </MDBox>
                    <FormikInput name={'additional_info'} label={'Additional Store Information'} type={"textarea"} rows={3} errors={errors} width={"100%"}/>

                    <MDButton
                      variant="contained"
                      color="primary"
                      loading={loading}
                      disabled={loading || !isValid}
                      sx={{maxWidth: pxToRem(280)}}
                      type='submit'
                    >
                      Save store
                    </MDButton>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </MDBox>
        </MDBox>
      </MDBox>

    </AdminLayout>
  );
}

export default AdminStoreDetail;
