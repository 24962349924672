import AdminLayout from "../../../components/AdminLayout";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useApi } from "../../../services/helpers";
import { PaginationCustom } from "../../../components/DataTableSimple/PaginationCustom";
import she from "assets/icons/she.png";
import Card from "@mui/material/Card";

export const validationSchema = Yup.object().shape({
  response: Yup.string(),
});

export const initialValues = {
  response: "",
};

export const PaymentManagement = () => {
  const api = useApi();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [replyInput, setReplayInput] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    start: 1,
    end: 4,
    currentPage: 1,
  });

  const sendReplay = (id, data) => {
    setLoading(true);
    api.sendResponseToFeedBack(id, data).handle({
      onSuccess: () => {
        getDrivers({ search: searchQuery });
        setLoading(false);
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Response sent",

    });
  };

  const getDrivers = (search) => {
    setLoading(true);
    api.getDrivers({ ...search, page_size: pageSize }).handle({
      onSuccess: (result) => {
        const { count, end, start, results } = result?.data;
        setLoading(false);
        setDrivers(results);
        setPaginationData(value => ({
          ...value,
          counts: count,
          start: start,
          end: end,
          itemsPerPage: results?.length,
        }));
        console.log(result);
      },
      errorMessage: "No data found",
    });
  };

  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 };
    setPaginationData(value => ({ ...value, currentPage: filters.page }));
    return filters;
  };

  const handleSubmit = () => {
    formikRef?.current?.handleSubmit();
    setReplayInput(null);
  };

  useEffect(() => {
    getDrivers({ search: searchQuery, page: paginationData.currentPage });
  }, [searchQuery, pageSize]);


  return (
    <AdminLayout title={"Payments Managements"}>
      <MDBox width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}
             alignItems={"flex-start"}>
        <MDBox width={"100%"} display={"flex"} flexDirection={"column"} gap={2} mt={2}>
          {drivers?.length
            ? drivers.map(item => (
              <MDBox key={`id-${item?.id}`} width={"100%"}>
                <Card>
                  <MDBox p={2}>
                    <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
                      <MDBox display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}
                             py={2}>
                        <MDBox display={"flex"} alignItems={"center"} gap={1} maxWidth={{xs:"250px", md:'100%'}}>
                          <Box component={"img"}
                               src={item?.client?.profile_picture ? item?.client?.profile_picture : she} alt={"guy"}
                               borderRadius={"50%"} width={{xs:"44px", md:"64px"}} height={{xs:"44px", md:"64px"}} ml={{xs:0, md:1}}/>
                          <MDBox ml={2}>
                            <MDBox display={"flex"}  alignItems={"center"} maxWidth={{xs:'182px',sm:'100%'}} sx={{flexWrap:{xs:'wrap', sm:'nowrap'}, whiteSpace: 'nowrap', overflow:'hidden', textOverflow:'ellipsis',}} >
                              <MDTypography sx={{fontSize: {xs: "16px", md:'20px'}, whiteSpace: 'nowrap', overflow:'hidden', textOverflow:'ellipsis', fontWeight: 700, marginRight:2}} >
                                {item?.name}asdasdsd sdasd
                              </MDTypography>
                              <MDTypography sx={{ fontSize: {xs: "12px", md:'14px', overflow:'hidden', textOverflow:'ellipsis'},}}>{item?.email}</MDTypography>
                            </MDBox>
                            <MDTypography sx={{color: "#0095FE", fontSize: "12px"}}>
                              {item?.is_company_driver ? 'Company Driver' : "Company Driver"}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDTypography>350$</MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
                {/*{replyInput === item?.id &&*/}
                {/*  <MDBox bgColor={"#fff"} p={2} borderRadius={"md"} mt={0.5}>*/}
                {/*    <Formik*/}
                {/*      innerRef={formikRef}*/}
                {/*      initialValues={initialValues}*/}
                {/*      validationSchema={validationSchema}*/}
                {/*      validateOnBlur={false}*/}
                {/*      onSubmit={values => sendReplay(item?.id, values)}*/}
                {/*    >*/}
                {/*      {(formik) => (*/}
                {/*        <Form>*/}
                {/*          <FormikInput name={"response"} type={"textarea"} rows={3}*/}
                {/*                       placeholder={"Write a response here..."} errors={formik.errors} />*/}
                {/*          <MDBox display={"flex"} justifyContent={"flex-end"} gap={2}>*/}
                {/*            <MDButton*/}
                {/*              loading={loading}*/}
                {/*              color={"primary"}*/}
                {/*              sx={{ height: 30, minHeight: 30, width: 104, textTransform: "capitalize" }}*/}
                {/*              onClick={() => handleSubmit()}*/}
                {/*            >*/}
                {/*              Send*/}
                {/*            </MDButton>*/}
                {/*            <MDButton*/}
                {/*              variant={"outlined"}*/}
                {/*              color={"primary"}*/}
                {/*              sx={{ height: 30, minHeight: 30, width: 104, textTransform: "capitalize" }}*/}
                {/*              onClick={() => setReplayInput(null)}*/}
                {/*            >*/}
                {/*              Cancel*/}
                {/*            </MDButton>*/}
                {/*          </MDBox>*/}
                {/*        </Form>*/}
                {/*      )}*/}
                {/*    </Formik>*/}
                {/*  </MDBox>*/}
                {/*}*/}
              </MDBox>
            ))
            : <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"} width={"100%"}>
              <MDTypography variant={"subtitle1"}>No data found</MDTypography>
            </MDBox>
          }
        </MDBox>
        <MDBox width={"100%"} height={80}>
          <PaginationCustom
            currentPage={paginationData.currentPage}
            totalCount={paginationData.counts}
            startPage={paginationData.start}
            endPage={paginationData.end}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              getDrivers({ ...getFilteredPage(), page: page });
              setPaginationData(value => ({ ...value, currentPage: page }));
            }}
          />

        </MDBox>
      </MDBox>

    </AdminLayout>
  );
};
