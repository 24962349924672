import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useState } from "react";
import { useApi } from "../../services/helpers";
import { useLocation, useParams } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import prohibitIcon from "../../assets/icons/Prohibit.svg";
import trashIcon from "../../assets/icons/TrashSimple.svg";
import { Checkbox, Dialog, Grid, useMediaQuery } from "@mui/material";
import MDButton from "../../components/MDButton";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/FormikInput";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { DRIVER_STATUS, MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import { ROUTES } from "../../services/constants";
import reactive from "../../assets/icons/reactiveUser.svg";
import imageSquare from "../../assets/icons/ImageSquare.svg"
import ProductOrderListPage from "../../pages/dashboard/orderManagements/orders";


export const DRIVER_INFORMATION = {
  email: "Email",
  date_of_birth: "Date of birth",
  address: "Address",
  city: { label: "City", key: "label" },
  state: { label: "State", key: "label" },
  zip_code: "ZIP",
  about_me: "About Me",
  is_company_driver: "Company Driver"
};

export const DRIVER_DOCUMENTS = {
  truck_model: "Truck Make & Model",
  truck_color: "Truck Color",
  license_plate: "License Plate",
  secondary_id: "Secondary Form of ID",
  driver_license: "Driver License",
  truck_picture: "Truck Picture",
};


function AdminDriversDetails() {
  const api = useApi();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isButtonHovered, setButtonHovered] = useState(false);
  const navigate = useNavigate();
  const [modalName, setModalName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [checkIsDriver, setCheckIsDriver] = useState(state.is_company_driver === "True" ? true : false)

  const isSmallScreen = useMediaQuery('(min-width:320px) and (max-width:769px)');

  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setButtonHovered(false);
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  const initialValues = {
    description: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      requestMoreInfoDriver(state.driver_id);
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  //Deny driver request
  const denyDriver = (id) => {
    setLoading(true);
    api.denyDriver(id).handle({
      onSuccess: (res) => {
        navigate("/dashboard/drivers/");
      },
      onError: (err) => {
      },
      errorMessage: "Error denying driver request",
      successMessage: "Driver request denied",
      onFinally: () => setLoading(false),
    });
  };

  //Approve driver request
  const approveDriver = (id) => {
    setLoading(true);
    api.approveDriver(id).handle({
      onSuccess: (res) => {

        navigate("/dashboard/drivers/");
      },
      onError: (err) => {
      },
      errorMessage: "Error approving driver request",
      successMessage: "Driver request approved",
      onFinally: () => setLoading(false),
    });
  };

  //Request driver more info
  const requestMoreInfoDriver = (id) => {
    setLoading(true);
    api.requestMoreInfoDriver(id).handle({
      onSuccess: (res) => {
        navigate("/dashboard/drivers/");
      },
      onError: (err) => {
      },
      errorMessage: "Error sending more details request",
      successMessage: "Sending more details request to the driver",
      onFinally: () => setLoading(false),
    });
  };

  //Update is company drive
  const saveDriverInfo = () => {

    const data = {
      user: "",
      name: "",
      phone_number: '',
      profile_picture: '',
      is_flagged: '',
      status: '',
      is_company_driver: checkIsDriver ? 'True' : 'False',
    }

    setLoading(true);
    api.isCompanyDriver(state?.driver_id, data).handle({
      onSuccess: (res) => {
        console.log("res", res);
        navigate("/dashboard/drivers/");
      },
      onError: (err) => {
        console.log("err", err);
      },
      errorMessage: "Error approving driver request",
      successMessage: "Driver request approved",
      onFinally: () => setLoading(false),
    });
  }

  // Approved Functions for driver
  const blockUser = () => {
    setLoading(true)
    api.blockUser(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false);
      },
      errorMessage: "Error blocking user",
      onError: err => {
      },
      onFinally: () => setLoading(false),
    },
    );
  };

  const deleteAccount = () => {
    setLoading(true);
    api.deleteAccount(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false);
      },
      errorMessage: "Error deactivating user",
      onError: err => {
      },
      onFinally: () => setLoading(false),
    },
    );
  };

  const reactivateUser = () => {
    setLoading(true);
    api.reactivateUser(state?.id).handle({
      onSuccess: () => {
        setOpenModal(false);
      },
      errorMessage: "Error reactivating user",
      onError: err => {
      },
      onFinally: () => setLoading(false),
    },
    );
  };
  const handleConfirmModal = () => {
    if (modalName === MODAL_NAME.CANCEL_MODAL) blockUser()
    else if (modalName === MODAL_NAME.DELETE_MODAL) deleteAccount()
    else if (modalName === MODAL_NAME.REACTIVE_MODAL) reactivateUser()
    navigate(ROUTES.ADMIN_DRIVERS)
  }

  return (
    <AdminLayout
      title={"Drivers"}
    >
      <MDBox width={"100%"}>
        <Grid container
          p={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={{ sx: "100%", md: "120px" }}
          borderRadius={"10px"}
          mb={5}
          sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <MDBox display={"flex"} alignItems={"center"}>
            <img
              alt="Remy Sharp"
              src={state?.profile_picture}
              style={{ width: 55, height: 55, borderRadius: 65 }}
            />
            <MDTypography color={"black"} variant={"h3"} ml={2}
              sx={{ fontSize: { xs: "23px", md: "24px", overflow: "hidden", textOverflow: "ellipsis" } }}>
              {state?.name}
            </MDTypography>
          </MDBox>
          <MDBox
            display={"flex"}
            alignItems={"center"}
            gap={1}
            paddingX={1}
            justifyContent={"space-between"}
          >
            {state?.driver_status === USER_STATUS.COMPLETED ? (
              <>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenModal(true);
                    setModalName(MODAL_NAME.CANCEL_MODAL);
                  }}
                >
                  <img src={prohibitIcon} alt="edit" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setModalName(MODAL_NAME.DELETE_MODAL);
                    setOpenModal(true);
                  }}
                >
                  <img src={trashIcon} alt="edit" />
                </IconButton>
              </>
            ) : state?.driver_status === USER_STATUS.CREATED ? (
              <>
                <Grid container alignItems={"center"} item xs={12} md={12} gap={2} marginTop={{ xs: 2, md: 0 }}>
                  <MDButton
                    color={"dark"} sx={{ borderRadius: "20px", width: { xs: "45%", md: "auto" } }} onClick={openDialog}
                  >
                    Need more details
                  </MDButton>
                  <MDButton
                    color={"error"}
                    sx={{ borderRadius: "20px", width: { xs: "45%", md: "auto" } }}
                    onClick={() => {
                      if (state && state.id) {
                        denyDriver(state.driver_id);
                      } else {
                        console.error("Invalid state or state.id is undefined");
                      }
                    }}
                  >
                    Deny request
                  </MDButton>
                  <MDButton
                    color={"info"}
                    sx={{ borderRadius: "20px", width: { xs: "100%", md: "auto" } }}
                    onClick={() => {
                      if (state && state.id) {
                        approveDriver(state.driver_id);
                      } else {
                        console.error("Invalid state or state.id is undefined");
                      }
                    }}
                  >
                    Approve request
                  </MDButton>
                </Grid>
              </>
            ) : state?.driver_status === USER_STATUS.CANCELLED ? (
              <>
                <IconButton
                  onClick={() => {
                    setOpenModal(true);
                    setModalName(MODAL_NAME.REACTIVE_MODAL);
                  }}
                >
                  <img src={reactive} alt="edit" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    setOpenModal(true);
                    setModalName(MODAL_NAME.CANCEL_MODAL);
                  }}
                >
                  <img src={prohibitIcon} alt="edit" />
                </IconButton>
              </>
            )}

          </MDBox>
        </Grid>

        <Grid container justifyContent={"space-between"} gap={2}>
          <Grid container item xs={12} md={5.8} display={'flex'} flexWrap={'nowrap'} direction={'column'}
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)", paddingBottom: isSmallScreen ? '85px' : 3 }}
            p={3}
            position={'relative'}
          >
            <Grid xs={12} display={'flex'} justifyContent={'center'} sx={{ flexBasis: '0px !important' }} borderBottom="1px solid #D9D9D9" mb={2} height={'33px'}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Account Information
              </MDTypography>
            </Grid>
            <Grid item container>
              {Object.entries(DRIVER_INFORMATION).map(([key, value], index) => {
                return (
                  <React.Fragment key={key}>
                    <Grid item xs={6} md={6} bgcolor={index % 2 ? "#F5F5F5" : "white"} display={'flex'} alignItems={'center'}>
                      <MDTypography color={"#364861"}>{value.label || value}</MDTypography>
                    </Grid>
                    <Grid item xs={6} md={6} bgcolor={index % 2 ? "#F5F5F5" : "white"} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} paddingRight={1}>
                      <MDTypography sx={{ textAlign: "right", overflow: 'hidden', minWidth: '50px', textOverflow: 'ellipsis' }}>
                        {key !== 'is_company_driver' && (state?.[key] || state?.[key]?.[value.key] || '')}
                      </MDTypography>
                      {key === 'is_company_driver' && (
                        <Checkbox checked={checkIsDriver} onClick={(e) => setCheckIsDriver(e?.target?.checked)} />
                      )}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <MDButton onClick={saveDriverInfo} variant="contained" color="primary" sx={{ borderRadius: 5, width: 'fit-content', position: 'absolute', bottom: 24, right: 24 }}>
              Save
            </MDButton>
          </Grid>
          <Grid container item xs={12} md={5.8}
            borderRadius={"10px"}
            sx={{ boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)" }}
            p={3}
          >
            <Grid item xs={12} display={'flex'} justifyContent={'center'} borderBottom="1px solid #D9D9D9" mb={2}>
              <MDTypography color={"black"} variant={"body"} ml={1}>
                Custom Information
              </MDTypography>
            </Grid>
            <Grid item container>
              {Object.entries(DRIVER_DOCUMENTS).map(([key, label], index) => (
                <>
                  <Grid item xs={6} md={6} bgcolor={index % 2 ? "#F5F5F5" : "white"} display={'flex'} alignItems={'center'}>
                    <MDTypography color={"#364861"}>{label}</MDTypography>
                  </Grid>
                  <Grid display={'flex'} item xs={6} md={6} bgcolor={index % 2 ? "#F5F5F5" : "white"} justifyContent={'flex-end'} paddingRight={1}>
                    {key === "driver_license" || key === "truck_picture" || key === "secondary_id" ? (
                      <MDBox sx={{ display: "flex", alignItems: "center", width: '100px', height: '100px', padding: 1 }}>
                        <MDBox component="img" src={state[key] || imageSquare} alt={label} width={'100%'} borderRadius={5} />
                      </MDBox>
                    ) : (
                      <MDTypography sx={{ textAlign: "right" }}>{state[key]}</MDTypography>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {state?.driver_status === DRIVER_STATUS.APPROVED ? (
          <MDBox mt={7}>
            <ProductOrderListPage driverId={state.driver_id} />
          </MDBox>) : (
          <>
          </>
        )}
      </MDBox>


      <Dialog fullWidth={true} open={isDialogOpen} onClose={closeDialog}>
        <MDBox width={"100%"}>
          <MDBox p={7}>
            <MDBox display="flex" flexDirection="column" alignItems="center">
              <MDTypography sx={{ fontSize: "20px", fontWeight: 700 }}>Need more details</MDTypography>
              <MDTypography mt={3} mb={5} sx={{ fontSize: "16px" }}>{state.email}</MDTypography>
            </MDBox>
            <FormikProvider value={formik}>
              <FormikInput
                name={"description"}
                label={"Description"}
                placeholder={"Input announcement description..."}
                errors={formik.errors}
                type={"textarea"}
                rows={3}
              />
            </FormikProvider>
            <MDBox display={"flex"} flexDirection={"column"} gap={3} mt={3} pl={5} pr={5}>
              <MDButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading || !formik.isValid || !formik.dirty}
                onClick={() => {
                  formik.handleSubmit();
                  closeDialog();
                }}
              >
                Send
              </MDButton>
              <MDButton variant="outlined" color="error" onClick={closeDialog}>
                Cancel
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Dialog>
      <ConfirmModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleConfirmModal}
        title={`${modalName} user?`}
        subtitle={`Are you sure you want to ${modalName} ${state?.name}?`}
      />
    </AdminLayout>
  );
}

export default AdminDriversDetails;



