import React, { useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import { Autocomplete, Button, IconButton, TextField, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { showMessage } from "services/helpers";
import { apiMessages } from "services/api/api-messages";
import { wrapApiCall } from "services/helpers_ts";
import { useApi } from "services/helpers";

import { BaseModal } from "components/BaseModal";
import FormikInput from "components/FormikInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import QuoteCardHeader from "./QuoteCardHeader";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { ORDER_TYPE } from "utils/helpers";

const UpdateQuoteModal = ({ order, formError, updateFormError, isOpen, onClose }) => {
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tankOptions, setTankOptions] = React.useState([]);

  const updateFormikRef = React.useRef();
  const api = useApi();

  const closeConfirmHandler = () => {
    setConfirmIsOpen(false);
  };

  const submitFormHandler = () => {
    const values = updateFormikRef.current?.values;

    const isCustomPricing = values?.pricing_type === "2";
    let pricesAreCompleted = true;
    if (isCustomPricing) {
      values?.tank_prices.forEach((item) => {
        if (!item?.size || !item?.price) {
          pricesAreCompleted = false;
          return;
        }
      });
    }

    if (!pricesAreCompleted) {
      updateFormError("Please complete the form");
      return;
    } else {
      updateFormError("");
    }

    setConfirmIsOpen(true);
  };

  const submitConfirmHandler = () => {
    const values = updateFormikRef.current?.values;

    const isCustomPricing = values?.pricing_type === "2";

    const orderId = order?.id;
    const updatedOrder = {
      admin_message: values?.message || "",
      status: ORDER_TYPE.ACCEPTED_BY_ADMIN,
      tank_transactions: undefined,
    };

    if (isCustomPricing) {
      updatedOrder.tank_transactions = values?.tank_prices?.map((tank) => {
        const updatedTank = {
          id: tank.size.id,
        };
        if (tank?.size?.is_exchange) {
          updatedTank.exchange_price = tank?.price.toString();
          updatedTank.buying_price = tank?.size?.buying_price;
        } else {
          updatedTank.exchange_price = tank?.size?.exchange_price;
          updatedTank.buying_price = tank?.price.toString();
        }

        return updatedTank;
      });
    }

    wrapApiCall(api.updateCustomOffer(orderId, updatedOrder), {
      onSuccess: (_result) => {
        showMessage(apiMessages.update.success("Update order quote"), "success");

        const goBack = true;
        onClose(goBack);
      },
      onError: (_error) => {
        showMessage(apiMessages.update.error("Update order quote"), "error");
      },
      onFinally: () => {
        setLoading(false);
        closeConfirmHandler();
      },
    });
  };

  useEffect(() => {
    const options = order?.tank_transactions?.map((tank) => ({
      ...tank,
      label: `${tank?.is_exchange ? "Exchange" : "Buy"} (${tank?.quantity}) ${tank.size?.name}`,
    }));
    setTankOptions(options);
  }, [order?.tank_transactions]);

  return (
    <>
      <BaseModal open={isOpen} handleClose={() => onClose()}>
        <MDBox display="flex" flexDirection="column" alignItems="center" gap={4} m={4}>
          <Typography variant="h3" color="#000">
            Update Quote
          </Typography>

          <QuoteCardHeader
            orderId={order?.order_id}
            name={order?.customer?.name}
            email={order?.customer?.email}
            phoneNumber={order?.customer?.phone_number}
          />

          {/* Pricing form */}
          <MDBox display="flex" flexDirection="column" width="100%">
            <Formik
              innerRef={updateFormikRef}
              initialValues={{
                pricing_type: "1",
                tank_prices: [{ price: "", size: null }],
                message: "",
              }}
              onSubmit={submitFormHandler}
            >
              {({ errors, values, setFieldValue }) => (
                <Form>
                  {/* Pricing type */}
                  <MDBox mb={4}>
                    <Typography fontSize={18} color="#000">
                      Pricing
                    </Typography>
                    <FormikInput
                      name={"pricing_type"}
                      label=""
                      errors={errors}
                      type="radio_group"
                      options={[
                        {
                          value: 1,
                          label: "Default Pricing",
                        },
                        {
                          value: 2,
                          label: "Custom Pricing",
                        },
                      ]}
                      row={true}
                    />
                  </MDBox>
                  {/* End Pricing type */}

                  {/* Pricing array form */}
                  {values?.pricing_type === "2" && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      sx={{ width: "100%" }}
                      mb={4}
                    >
                      <FieldArray name={"tank_prices"}>
                        {(fieldArrayProps) => {
                          const fieldName = fieldArrayProps.name;

                          return (
                            <>
                              {values?.tank_prices?.map((_item, index) => (
                                <MDBox
                                  display="flex"
                                  alignItems="flex-start"
                                  mb={4}
                                  sx={{
                                    width: "100%",
                                  }}
                                  key={`tank-price-form-item-${index}`}
                                >
                                  <Autocomplete
                                    disablePortal
                                    name={`${fieldName}[${index}].size`}
                                    id={`${fieldName}[${index}].size`}
                                    options={tankOptions.filter(
                                      (option) =>
                                        !values?.tank_prices?.some(
                                          (selected) => selected?.id === option?.id
                                        )
                                    )}
                                    disableClearable={true}
                                    getOptionDisabled={(option) =>
                                      values?.tank_prices?.some(
                                        (tank) => tank?.size?.id === option?.id
                                      )
                                    }
                                    sx={{
                                      width: "100%",
                                      marginRight: 2,
                                    }}
                                    onChange={(_e, value) => {
                                      setFieldValue(`${fieldName}[${index}].size`, value);
                                      setFieldValue(
                                        `${fieldName}[${index}].price`,
                                        value?.is_exchange
                                          ? value?.exchange_price
                                          : value?.buying_price
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Tank Size" variant="outlined" />
                                    )}
                                  />

                                  <FormikInput
                                    name={`${fieldName}[${index}].price`}
                                    label="Tank Price"
                                    type="number"
                                    sx={{
                                      width: "100%",
                                    }}
                                  />

                                  {index !== 0 && (
                                    <IconButton
                                      sx={{
                                        ml: 2,
                                        mb: 2,
                                      }}
                                      onClick={() => {
                                        fieldArrayProps.remove(index);
                                      }}
                                    >
                                      <DeleteOutlineIcon fontSize={"medium"} color="error" />
                                    </IconButton>
                                  )}
                                </MDBox>
                              ))}

                              {tankOptions.length > values?.tank_prices?.length && (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    fieldArrayProps.push({ size: null, price: "" });
                                  }}
                                >
                                  + Add Tank Size
                                </Button>
                              )}
                            </>
                          );
                        }}
                      </FieldArray>
                    </MDBox>
                  )}
                  {/* End Pricing array form */}

                  {/* Message */}
                  <MDBox display="flex" alignItems="center">
                    <FormikInput
                      name={"message"}
                      label="Message"
                      errors={errors}
                      type="textarea"
                      multiline={true}
                      rows={4}
                      sx={{ width: "100%" }}
                    />
                  </MDBox>
                  {/* End Message */}

                  <Typography color="error" sx={{ marginBottom: 2, marginTop: 0 }} fontSize={14}>
                    {formError}
                  </Typography>

                  <MDButton variant="contained" color="primary" fullWidth type="submit">
                    Submit Quote
                  </MDButton>
                </Form>
              )}
            </Formik>
          </MDBox>
          {/* End Pricing form */}
        </MDBox>
      </BaseModal>

      {confirmIsOpen && (
        <ConfirmModal
          title="Are you sure that you want to confirm this Quote Update?"
          open={confirmIsOpen}
          handleClose={closeConfirmHandler}
          handleConfirm={submitConfirmHandler}
          loading={loading}
        />
      )}
    </>
  );
};

export default UpdateQuoteModal;
