import { Modal } from "@mui/material";

import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";

function ConfirmModal({ handleClose, open, handleConfirm, title, subtitle, loading }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#fff",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
          width: 400,
          boxShadow: 24,
        }}
      >
        <MDTypography pt={2} sx={{ textAlign: "center", fontSize: "22px" }}>
          {title}
        </MDTypography>
        <MDTypography pt={2} sx={{ textAlign: "center", fontSize: "16px" }}>
          {subtitle}
        </MDTypography>
        <MDBox
          pb={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          pt={2}
        >
          <MDButton
            color={"primary"}
            onClick={() => {
              handleConfirm();
            }}
            loading={loading}
          >
            Confirm
          </MDButton>
          <MDButton
            variant={"outlined"}
            color="secondary"
            onClick={() => {
              handleClose();
            }}
            loading={loading}
          >
            Cancel
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

export default ConfirmModal;
