import MDBox from "components/MDBox";
import {CircularProgress, Input} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import pxToRem from "../../assets/theme/functions/pxToRem";
import debounce from "lodash.debounce";

const CssTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width:"100%",
    // minWidth: '100%',
    maxWidth: '480px',
    height: "44px",
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "0 5px 25px 2px rgba(0, 0, 0, 0.11)",
    color: "#364861",
    fontSize: "14px",
  },
  '& label.Mui-focused': {
    color: '#364861',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#364861',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#364861',
    },
    '&:hover fieldset': {
      borderColor: '#364861',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#364861',
    },
  },
  '.MuiOutlinedInput-input': {
    color: "#364861"
  }
});

const SearchBar = ({search, loading, setSearchQuery, placeholder = "Search"}) => {
  const delayedSearch = debounce(setSearchQuery, 300);

  const searchFunc = (text = '') => {
    delayedSearch(text)
    if (search) {
      search(text)
    }
  }

  return (
    <MDBox mr={3} sx={{boxShadow: "0px 2px 8px -1px rgba(0, 0, 0, 0.25)", borderRadius: "40px", width:"100%", maxWidth: '480px',}} >
      <CssTextField
        fullWidth
        placeholder={placeholder}
        type="text"
        onChange={(evt) => searchFunc(evt?.target?.value)}
        size="small"
        InputProps={{
          endAdornment: loading === false ? <Search/> : <CircularProgress size={14} color="primary"/>,
        }}
      />
    </MDBox>
  )

}

export default SearchBar;
