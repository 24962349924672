import React from "react";
import { Grid } from "@mui/material";

import PageLayout from "layouts/pages/public";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultNavbar from "components/DefaultNavbar";

import { publicRoutes as pageRoutes } from "page.routes";
import { termsContent } from "pages/legal/LEGAL_CONTENT.js";
import Footer from "components/Footer";

const TermsOfUse = () => {
  return (
    <PageLayout background="light">
      <DefaultNavbar routes={pageRoutes} />

      <MDBox px={1} width="100%" height="auto" mx="auto" mt={24}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} md={8}>
            <MDTypography variant="h1" color="dark" textAlign="center">
              Lit
            </MDTypography>
            <MDTypography variant="h1" color="dark" textAlign="center" mt={4}>
              Terms Of Use
            </MDTypography>
          </Grid>

          <Grid item xs={12} md={8} my={8}>
            <div dangerouslySetInnerHTML={{ __html: termsContent }}></div>
          </Grid>
        </Grid>
      </MDBox>

      <Footer links={pageRoutes} />
    </PageLayout>
  );
};

export default TermsOfUse;
