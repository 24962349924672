
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";

// Components
import AdminLayout from "../../../../components/AdminLayout";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Utils
import { useApi } from "../../../../services/helpers";
import { useApiMessages } from "../../../../services/api/api-messages";
import { ORDER_PERIOD_FILTER, ORDER_STATUS, STATUS_COLORS } from "../../../../utils/helpers";

// Styles
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ROUTES } from "../../../../services/constants";
import { Container, FilterItem, FilterItemModified, InputContainer } from "../filterBars";
import { Grid } from "@mui/material";
import Pagination from "../../../../components/DataTable/Pagination/Pagination";
import { wrapApiCall } from "../../../../services/helpers_ts";
import Card from "@mui/material/Card";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDButton from "../../../../components/MDButton";
import Divider from "@mui/material/Divider";
import SearchBar from "../../../../components/SearchBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import carafeIcon from "../../../../assets/icons/carafeIcon.png";

const PageWrapper = ({ children, driverId, clientId }) => {
  if (driverId) {
    return (
      <>
        {children}
      </>
    );

  } else if (clientId) {
    return (
      <>
        {children}
      </>
    );
  } else {
    return (
      <AdminLayout title={"Order Management"}>
        {children}
      </AdminLayout>
    );
  }

};

function ProductOrderListPage({ showTotalEntries = true, driverId, clientId, customer_id }) {
  const api = useApi();
  const navigate = useNavigate();
  const { ido: orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const messages = useApiMessages("order", "orders");

  const [anchorEl, setAnchorEl] = useState(null);
  const [period, setPeriod] = useState("");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getRegularOrder = (search = "", page = 1, ordering = paginationData.ordering) => {
    setLoading(true);

    const params = {
      order_id: orderId,
      search,
      page,
      ordering,
      period,
      ...(driverId ? { driver_id: driverId } : customer_id ? { client_id: clientId } : '')
    };

    wrapApiCall(api.getRegularOrder(params), {
      onSuccess: (result) => {
        const { count, results } = result.data;
        setProducts(results);
        setPaginationData((value) => ({
          ...value,
          counts: count,
          itemsPerPage: results?.length,
        }));
      },
      errorMessage: messages.list.error,
      onFinally: () => setLoading(false),
    });
  };

  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    const { search, page } = getFilteredPage()
    getRegularOrder(search, page)
  }, [searchQuery, period]);

  const sumTankQuantities = (tankTransactions) => {
    return tankTransactions.reduce((total, tank) => total + tank.quantity, 0);
  };

  return (

    <PageWrapper driverId={driverId} clientId={clientId}>
      <MDBox width="100%">
        <MDBox sx={Container}>
          <MDBox sx={InputContainer}>
            <MDBox width={"60%"} alignItems={"center"} mb={"10px"}>
              <SearchBar
                placeholder={"Search Orders"}
                loading={loading}
                search={getRegularOrder}
                setSearchQuery={setSearchQuery}
              />
            </MDBox>
            <MDButton
              variant="text"
              color="dark"
              size="large"
              onClick={handleMenuClick}
              endIcon={<ExpandMoreIcon />}
            >
              More
            </MDButton>
          </MDBox>
          <MDBox sx={FilterItemModified}></MDBox>
          <MDBox sx={FilterItem}></MDBox>
        </MDBox>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setPeriod(ORDER_PERIOD_FILTER.TODAY)
              handleClose()
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                m: 0.5,
                color: "#000",
                "&:hover": { color: "#fff" },
              }}
            >
              Today
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setPeriod(ORDER_PERIOD_FILTER.TOMORROW)
              handleClose()
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                m: 0.5,
                color: "#000",
                "&:hover": { color: "#fff" },
              }}
            >
              Tomorrow
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setPeriod(ORDER_PERIOD_FILTER.THIS_WEEK)
              handleClose()
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                m: 0.5,
                color: "#000",
                "&:hover": { color: "#fff" },
              }}
            >
              This week
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setPeriod(ORDER_PERIOD_FILTER.THIS_MONTH)
              handleClose()
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                m: 0.5,
                color: "#000",
                "&:hover": { color: "#fff" },
              }}
            >
              This month
            </Typography>
          </MenuItem>
        </Menu>
        <Grid container spacing={3} >
          {Products?.length > 0 ? (
            Products.map((product, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={`product-key-${index}`}
                onClick={() => {
                  navigate(ROUTES.ORDER_DETAILS(product.id, orderId), { state: product })
                }}
              >
                <Card
                  sx={{
                    boxShadow: "0 5px 25px 2px rgba(0, 0, 0, 0.11)",
                    borderRadius: 7,
                    padding: 3,
                    cursor: "pointer",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                >
                  <MDBox display={"flex"} gap={5}>
                    <MDBox style={{ position: "relative", width: 50, height: 60, marginTop: 1 }}>
                      <MDBox
                        component="img"
                        src={carafeIcon}
                        style={{ width: "100%", height: "100%" }}
                      />
                      <MDBox
                        component="span"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {sumTankQuantities(product.tank_transactions)}
                      </MDBox>
                    </MDBox>
                    <Box>
                      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                        Order ID: {product.order_id}
                      </Typography>
                      <Typography sx={styles.title}>
                        Exchange {product.tank_transactions.is_exchange}
                      </Typography>
                      <Typography sx={styles.title}>Standard RV Tanks</Typography>
                    </Box>
                    <MDButton variant="contained" color="primary" sx={{ borderRadius: 5, mb: 7 }}>
                      View
                    </MDButton>
                  </MDBox>
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Delivery Date:</Typography>
                    <Typography sx={styles.response}>{product.delivery_date}</Typography>
                  </MDBox>
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Address:</Typography>
                    <Typography sx={styles.response}>{product.location?.address}</Typography>
                  </MDBox>
                  <Divider />
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Customer:</Typography>
                    <Typography sx={styles.response}>{product.name}</Typography>
                  </MDBox>
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Driver:</Typography>
                    <Typography
                      sx={styles.response}>{product.driver ? product.driver.name : "Unknown Driver"}</Typography>
                  </MDBox>
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Status:</Typography>
                    <Typography sx={{ fontWeight: 600, color: STATUS_COLORS[product.status] }}>
                      {ORDER_STATUS[product.status]}
                    </Typography>
                  </MDBox>
                  <Divider />
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Total cost:</Typography>
                    <Typography sx={styles.response}>{(parseFloat(product.tanks_total_cost) * 1.06).toFixed(2)}</Typography>
                  </MDBox>
                  <MDBox display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography sx={styles.subtitle}>Driver Fee:</Typography>
                    <Typography sx={styles.response}>{product.delivery_fee}</Typography>
                  </MDBox>
                </Card>
              </Grid>
            ))
          ) : (
            <MDBox
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"50vh"}
              width={"100%"}
            >
              <MDTypography variant={"subtitle1"}>No items found</MDTypography>
            </MDBox>
          )}
        </Grid>
        {showTotalEntries && (
          <Grid container>
            <Grid item>
              <MDBox m={2} sx={{ color: "#666666", fontSize: 17, width: 300 }}>
                Showing <span style={{ color: "#000000" }}>{paginationData.itemsPerPage}</span> from{" "}
                <span style={{ color: "#000000" }}>{paginationData.counts}</span> data
              </MDBox>
            </Grid>
            <Grid item ml={"auto"}>
              <Pagination
                currentPage={paginationData.currentPage}
                totalCount={paginationData.counts}
                pageSize={24}
                onPageChange={(page) => {
                  // page:page is to ensure that we have the current value in the filters
                  const { search } = getFilteredPage()
                  getRegularOrder(search, page);
                  setPaginationData((value) => ({ ...value, currentPage: page }));
                }}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </PageWrapper>
  );
}

export default observer(ProductOrderListPage);

const styles = {
  title: {
    fontWeight: 600,
    fontSize: "12px",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "14px",
  },
  response: {
    fontSize: "14px",
    fontWeight: 600,
  },
};
