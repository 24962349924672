/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

import litLogo from "assets/images/lit-logo.png";

function Footer({ links }) {
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" lineHeight={1}>
        <Link to={link.route}>
          <MDTypography variant="button" fontWeight="regular" color="light">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={{ xs: 0, lg: 16 }}
      py={{ xs: 8, lg: 0 }}
      bgColor="dark"
      minHeight={256}
    >
      <MDBox
        display="flex"
        flex={1}
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        md={8}
        maxWidth={1440}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          px={1.5}
          mb={{ xs: 8, lg: 0 }}
        >
          <MDBox
            component={"img"}
            src={litLogo}
            alt={"lit-logo"}
            shadow="none"
            width={82}
            height={82}
          />
          <MDBox>
            <MDTypography variant="h2" fontSize={size.lg} color="light" fontWeight="medium">
              Neet It Lit
            </MDTypography>
            <MDTypography fontSize={size.sm} color="secondary" fontWeight="medium">
              &copy; {new Date().getFullYear()}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox display="flex" flexDirection="column" minWidth={200}>
          <MDTypography variant="h2" fontSize={size.lg} color="light" fontWeight="medium">
            Useful Links
          </MDTypography>

          <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            gap={2}
            listStyle="none"
            mt={2}
            p={0}
          >
            {renderLinks()}
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
