import pxToRem from "../../assets/theme/functions/pxToRem";
import { Box } from "@mui/material";
import React from "react";
import pencilIcon from "../../assets/icons/pencil2.svg"
import trashIcon from "../../assets/icons/trash2.svg"
import IconButton from "@mui/material/IconButton";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import { ROUTES } from "../../services/constants";

export const MODAL_NAME_STORE = {
  CANCEL_MODAL: "cancel",
  DELETE_MODAL: "delete",
  REACTIVE_MODAL: "reactivate",
}

export const dataTableTankModel = {
  columns: [
    {Header: "Tank Size", accessor: "tank", disableOrdering: true},
    {Header: "Available", accessor: "available_amount", disableOrdering: true},
    {Header: "Exchange Price", accessor: "exchange_price", disableOrdering: true},
    {Header: "Buying Price", accessor: "buying_price", disableOrdering: true},
    {Header: "Action", accessor: "actions", disableOrdering: true},
  ],
  rows: [],
};

const popOver = (item, setOpenModal, setSelectedItem, navigate) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>

        <IconButton
          sx={{
            borderRadius: "10px",
            background: "#364861"
          }}
          onClick={()=>{
            setSelectedItem(item)
            if (item?.tank) navigate(ROUTES.ADMIN_EDIT_TANK(item.id), {state: {...item, actions:null} })
            else if (item?.name) navigate(ROUTES.ADMIN_EDIT_STORE(item.id), {state: {...item, actions:null} })
          }}
        >
          <img src={pencilIcon} alt="edit" />
        </IconButton>

      <IconButton
        sx={{
          borderRadius: "10px",
          background: "#364861"
        }}
        onClick={()=>{
          setSelectedItem(item)
          setOpenModal(true)
        }}
      >
        <img src={trashIcon} alt="edit" />
      </IconButton>
    </Box>
  )
}

export const renderTableTankRow = (item, setOpenModal, setSelectedItem, navigate) => {
  item.tank = item.tank || "No data"
  item.available_amount = item.available_amount || "No data"
  item.exchange_price = item.exchange_price || "No data"
  item.buying_price = item.buying_price || "No data"
  item.actions = (popOver(item, setOpenModal, setSelectedItem, navigate))
  return item
}

export const dataTableStoreModel = {
  columns: [
    {Header: "Propane Delivery Stores", accessor: "name",  disableOrdering: true},
    {Header: "Address", accessor: "address", disableOrdering: true},
    {Header: "Capacity", accessor: "capacity", disableOrdering: true},
    {Header: "Additional Info", accessor: "additional_info", disableOrdering: true},
    {Header: "Action", accessor: "actions", disableOrdering: true},
  ],
  rows: [],
};

export const renderTableStoreRow = (item, setOpenModal, setSelectedItem, navigate) => {
  item.name = item.name || "No data"
  item.address = item.address || "No data"
  item.capacity = item.capacity || "No data"
  item.additional_info = item.additional_info || "No data"
  item.actions = (popOver(item, setOpenModal, setSelectedItem, navigate))
  return item
}