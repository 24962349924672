import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { Grid, Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Components
import AdminLayout from "../../../../components/AdminLayout";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import {
  KeepMeForeverLit,
  PhoneOrder,
  EnvelopeIcon,
  IdentificationCard,
} from "../../../../assets/theme/components/SvgIcons";

// Utils
import { ROUTES } from "../../../../services/constants";
import { showMessage, useApi } from "../../../../services/helpers";
import { apiMessages } from "../../../../services/api/api-messages";
import { wrapApiCall } from "../../../../services/helpers_ts";
import { ORDER_TYPE } from "utils/helpers";
import DeclineQuoteModal from "components/Orders/DeclineQuoteModal";

// Styles

function OrderRequests({ showTotalEntries = true }) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [customOrderChoices, setCustomOrderChoices] = useState();
  const [paginationData, setPaginationData] = useState({
    count: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const [declineQuoteIsOpen, setDeclineQuoteIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [formError, setFormError] = useState("");

  const api = useApi();
  const navigate = useNavigate();

  const getCustomOrderPending = useCallback(() => {
    if (customOrderChoices) {
      setLoading(true);

      const params = {
        status: ORDER_TYPE.SUBMITTED,
        page: paginationData.currentPage,
      };
      api.getCustomOrder(params).handle({
        onSuccess: (result) => {
          const { count, results, page } = result.data;

          const formattedResults = results.map((order) => ({
            ...order,
            preferred_delivery_frequency: customOrderChoices.delivery_frequencies.find(
              (choice) => choice.value === order?.preferred_delivery_frequency
            ),
            preferred_delivery_days: order?.preferred_delivery_days?.map((day) =>
              customOrderChoices.delivery_days.find((delivery) => delivery.value === day)
            ),
            preferred_cabinet_size: customOrderChoices.cabinet_sizes.find(
              (size) => size.display === order?.preferred_cabinet_size
            ),
          }));
          console.log(formattedResults);
          setOrders(formattedResults);

          setPaginationData((value) => ({
            ...value,
            currentPage: page,
            count,
            itemsPerPage: results?.length,
          }));
        },
        errorMessage: apiMessages.list.error("Order Requests"),
        onFinally: () => setLoading(false),
      })
    }
  }, [customOrderChoices, paginationData.currentPage]);

  const getCustomOrderChoices = useCallback(() => {
    if (!customOrderChoices) {
      api.getCustomOrderChoices().handle({
        onSuccess: (result) => {
          setCustomOrderChoices(result.response);
        },
        errorMessage: apiMessages.get.error("Order choices"),
        onFinally: () => setLoading(false),
      });
    }
  }, []);

  const viewRequestButtonHandler = (order) => {
    console.log("The order data is: ",order)
    navigate(ROUTES.ADMIN_ORDER_REQUESTS_DETAILS(order?.id), { state: { order } });
  };

  const pageChangeHandler = (_event, page) => {
    setPaginationData((currVal) => ({ ...currVal, currentPage: page }));
  };

  const declineModalOpenHandler = (order) => {
    setSelectedOrder(order);
    setDeclineQuoteIsOpen(true);
    setFormError("");
  };

  const declineModalCloseHandler = () => {
    setSelectedOrder(null);
    setFormError("");
    setDeclineQuoteIsOpen(false);
    getCustomOrderPending();
  };

  useEffect(() => {
    getCustomOrderChoices();
  }, [getCustomOrderChoices]);

  useEffect(() => {
    getCustomOrderPending();
  }, [getCustomOrderPending]);

  return (
    <AdminLayout title={"Order Request"}>
      <MDBox width="100%">
        {/* Order List */}
        <Grid container spacing={2}>
          {loading ? (
            <MDBox
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"50vh"}
              width={"100%"}
            >
              <MDTypography variant={"subtitle1"}>Loading orders...</MDTypography>
            </MDBox>
          ) : (
            <>
              {orders?.length > 0 ? (
                orders.map((order, index) => (
                  <Grid item xs={12} md={6} lg={4} key={`order-key-${index}`}>
                    <Card
                      sx={{
                        margin: 2,
                        boxShadow: "0 5px 25px 2px rgba(0, 0, 0, 0.11)",
                        borderRadius: 7,
                        padding: 3,
                        width: "100%",
                        maxWidth: "400px",
                      }}
                    >
                      {/* Card header */}
                      <MDBox display={"flex"} gap={2}>
                        <KeepMeForeverLit sx={{ width: 30, mt: 1 }} fontSize={"large"} />
                        <Box>
                          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                            Order ID: {order?.order_id || "-"}
                          </Typography>
                          <Typography sx={styles.title}>Keep me Forever Lit</Typography>
                        </Box>
                      </MDBox>
                      {/* End Card header */}

                      {/* Customer identification */}
                      <MDBox display={"flex"} mt={3} flexDirection="column">
                        <MDBox display={"flex"}>
                          <IdentificationCard sx={{ width: 25 }} fontSize={"large"} />
                          <Typography sx={{ ...styles.response, pt: 1, ml: 2 }}>
                            {order?.customer?.name || "-"}
                          </Typography>
                        </MDBox>
                        <MDBox display={"flex"}>
                          <EnvelopeIcon sx={{ width: 25 }} fontSize={"large"} />
                          {/* TODO: BACKEND - Get custom order - Missing customer email on the API response */}
                          <Typography sx={{ ...styles.subtitle, pt: 1, ml: 2 }}>
                            {order?.customer?.email || "-"}
                          </Typography>
                        </MDBox>
                        <MDBox display={"flex"}>
                          <PhoneOrder sx={{ width: 25 }} fontSize={"large"} />
                          <Typography sx={{ ...styles.subtitle, pt: 1, ml: 2 }}>
                            {order?.customer?.phone_number || "-"}
                          </Typography>
                        </MDBox>
                      </MDBox>
                      {/* End Customer identification */}

                      <Divider />

                      {/* Tank transactions list items */}
                      {order?.tank_transactions?.length ? (
                        <>
                          {order?.tank_transactions.map((trx, trxIndex) => (
                            <React.Fragment key={`transaction-key-${trxIndex}`}>
                              <MDBox display={"flex"} justifyContent={"space-between"} mt={1}>
                                <Typography sx={styles.subtitle}>
                                  {trx?.is_exchange ? "Exchange" : "Buy"} Tank Size:
                                </Typography>
                                <Typography sx={styles.response}>
                                  {trx?.size?.name || "-"}
                                </Typography>
                              </MDBox>
                              <MDBox display={"flex"} justifyContent={"space-between"} mt={1}>
                                <Typography sx={styles.subtitle}>Number of Tanks:</Typography>
                                <Typography sx={styles.response}>{trx?.quantity || "-"}</Typography>
                              </MDBox>

                              <Divider />
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          <MDBox display={"flex"} justifyContent="center" mt={1}>
                            <Typography sx={styles.subtitle}>
                              This order has no tank transactions
                            </Typography>
                          </MDBox>

                          <Divider />
                        </>
                      )}
                      {/* End Tank transactions list items */}

                      {/* Cabinet info details */}
                      <MDBox display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Typography sx={styles.subtitle}>Need storage cabinet:</Typography>
                        <Typography sx={styles.response}>
                          {order?.needs_cabinet ? "Yes" : "No"}
                        </Typography>
                      </MDBox>
                      <MDBox display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Typography sx={styles.subtitle}>Cabinet size:</Typography>
                        <Typography sx={styles.response}>
                          {order?.preferred_cabinet_size?.display || "-"}
                        </Typography>
                      </MDBox>
                      {/* End Cabinet info details */}

                      <MDBox display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Typography sx={styles.subtitle}>Needs:</Typography>
                      </MDBox>
                      <Typography fontSize={12}>{order?.more_about || "-"}</Typography>

                      {/* Card footer actions */}
                      <MDBox display={"flex"} justifyContent={"space-between"} gap={2} mt={4}>
                        <MDButton
                          variant="contained"
                          color="secondary"
                          size={"large"}
                          sx={{ backgroundColor: "#364861", color: "#fff" }}
                          fullWidth
                          onClick={() => declineModalOpenHandler(order)}
                        >
                          Decline Offer
                        </MDButton>
                        <MDButton
                          variant="contained"
                          color="primary"
                          sx={{}}
                          fullWidth
                          onClick={() => viewRequestButtonHandler(order)}
                        >
                          View Request
                        </MDButton>
                      </MDBox>
                      {/* End Card footer actions */}
                    </Card>
                  </Grid>
                ))
              ) : (
                <MDBox
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  minHeight={"50vh"}
                  width={"100%"}
                >
                  <MDTypography variant={"subtitle1"}>No items found</MDTypography>
                </MDBox>
              )}
            </>
          )}
        </Grid>
        {/* Order List */}

        {/* Pagination */}
        {!loading && showTotalEntries && (
          <Grid container mt={5}>
            <Grid item>
              <MDBox m={2} sx={{ color: "#666666", fontSize: 17, width: 300 }}>
                Showing <span style={{ color: "#000000" }}>{paginationData.itemsPerPage}</span> from{" "}
                <span style={{ color: "#000000" }}>{paginationData.count}</span> orders
              </MDBox>
            </Grid>
            <Grid item ml={"auto"}>
              <Pagination
                count={Math.ceil(paginationData.count / 10)}
                page={paginationData.currentPage}
                onChange={pageChangeHandler}
              />
            </Grid>
          </Grid>
        )}
        {/* End Pagination */}
      </MDBox>

      {/* Decline offer modal */}
      <DeclineQuoteModal
        order={selectedOrder}
        formError={formError}
        updateFormError={setFormError}
        isOpen={declineQuoteIsOpen}
        onClose={declineModalCloseHandler}
      />
      {/* End Decline offer modal */}
    </AdminLayout>
  );
}

export default observer(OrderRequests);

const styles = {
  title: {
    fontWeight: 600,
    fontSize: "12px",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "14px",
  },
  response: {
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "right",
  },
};
