import pxToRem from "../../assets/theme/functions/pxToRem";
import { Box } from "@mui/material";
import MDButton from "../../components/MDButton";
import React from "react";
import warningIcon from "../../assets/icons/warningCircle.svg"
import prohibitIcon from "../../assets/icons/Prohibit.svg"
import trashIcon from "../../assets/icons/TrashSimple.svg"
import reactive from "../../assets/icons/reactiveUser.svg"
import IconButton from "@mui/material/IconButton";
import { ROUTES } from "../../services/constants";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";

export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Email", accessor: "email"},
    {Header: "Phone Number", accessor: "phone_number", disableOrdering: true},
    {Header: "Action", accessor: "actions", disableOrdering: true},
  ],
  rows: [],
};

const popOver = (item, setOpenModal, setSelectedItem, navigate, setModalName) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {/*<IconButton*/}
      {/*  onClick={()=>{*/}
      {/*    setSelectedItem(item)*/}
      {/*    // setOpenModal(true)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <img src={warningIcon} alt="edit" />*/}
      {/*</IconButton>*/}
      {item.user_status === USER_STATUS.CANCELLED?
        <IconButton
          onClick={()=>{
            setSelectedItem(item)
            setOpenModal(true)
            setModalName(MODAL_NAME.REACTIVE_MODAL)
          }}
        >
          <img src={reactive} alt="edit" />
        </IconButton>
        :
        <IconButton
          onClick={()=>{
            setSelectedItem(item)
            setOpenModal(true)
            setModalName(MODAL_NAME.CANCEL_MODAL)
          }}
        >
          <img src={prohibitIcon} alt="edit" />
        </IconButton>
      }

      <IconButton
        onClick={()=>{
          setSelectedItem(item)
          setOpenModal(true)
          setModalName(MODAL_NAME.DELETE_MODAL)
        }}
      >
        <img src={trashIcon} alt="edit" />
      </IconButton>
      <MDButton
        color={"primary"}
        sx={{borderRadius: "20px"}}
        onClick={()=>
          navigate(
            ROUTES.ADMIN_CUSTOMERS_DETAILS(item.id),
            { state: {
              ...item, 
              actions: null, 
              address: (item?.addresses || [])[0]?.address || "", 
              city: (item?.addresses || [])[0]?.city?.label || "",
              state: (item?.addresses || [])[0]?.state?.label || "",
              country: (item?.addresses || [])[0]?.country?.label || "",
              zip: (item?.addresses || [])[0]?.zip_code || "",
            }},
          )
        }
      >
        View
      </MDButton>
    </Box>
  )
}

export const renderTableRow = (item, setOpenModal, setSelectedItem, navigate, setModalName) => {
  item.actions = (popOver(item, setOpenModal, setSelectedItem, navigate, setModalName))
  return item
}