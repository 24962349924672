

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Image
import { React, useState } from "react";
import {
  AgeGroup,
  CustomersCount,
  TrafficOverview,
} from "./charts";
import AdminLayout from "../../components/AdminLayout";
import { Grid } from "@mui/material";

function AdminAnalytics() {
  const [calcHeight, setCalcHeight] = useState("850px")

  return (
    <AdminLayout
      title={'Analytics'}
    >
      <Grid container p={5}>
        <Grid item xs={12}>
          <TrafficOverview />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <MDBox mr={2} height={calcHeight}>
          <CustomersCount height={'100%'}/>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <AgeGroup height={'auto'} onHeightChange={setCalcHeight} />
        </Grid>
      </Grid>

    </AdminLayout>
  );
}

export default AdminAnalytics;
