import React from "react";
import { Typography } from "@mui/material";

import { IdentificationCard } from "assets/theme/components/SvgIcons";
import { PhoneOrder } from "assets/theme/components/SvgIcons";
import { EnvelopeIcon } from "assets/theme/components/SvgIcons";
import { KeepMeForeverLit } from "assets/theme/components/SvgIcons";
import MDBox from "components/MDBox";

const QuoteCardHeader = ({ orderId, name, email, phoneNumber }) => {
  return (
    <MDBox display="flex" flexDirection="column" width="100%">
      {/* Card header */}
      <MDBox display={"flex"} gap={2}>
        <KeepMeForeverLit sx={{ width: 30, mt: 1 }} fontSize={"large"} />
        <MDBox>
          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
            Order ID: {orderId || "-"}
          </Typography>
          <Typography sx={styles.title}>Keep me Forever Lit</Typography>
        </MDBox>
      </MDBox>
      {/* End Card header */}

      {/* Customer identification */}
      <MDBox display={"flex"} mt={3} flexDirection="column">
        <MDBox display={"flex"}>
          <IdentificationCard sx={{ width: 25 }} fontSize={"large"} />
          <Typography sx={{ ...styles.response, pt: 1, ml: 2 }}>{name || "-"}</Typography>
        </MDBox>
        <MDBox display={"flex"}>
          <EnvelopeIcon sx={{ width: 25 }} fontSize={"large"} />
          {/* TODO: BACKEND - Get custom order - Missing customer email on the API response */}
          <Typography sx={{ ...styles.subtitle, pt: 1, ml: 2 }}>{email || "-"}</Typography>
        </MDBox>
        <MDBox display={"flex"}>
          <PhoneOrder sx={{ width: 25 }} fontSize={"large"} />
          <Typography sx={{ ...styles.subtitle, pt: 1, ml: 2 }}>{phoneNumber || "-"}</Typography>
        </MDBox>
      </MDBox>
      {/* End Customer identification */}
    </MDBox>
  );
};

export default QuoteCardHeader;

const styles = {
  title: {
    fontWeight: 600,
    fontSize: "12px",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "14px",
  },
  response: {
    fontSize: "14px",
    fontWeight: 600,
  },
};
