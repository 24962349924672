import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import * as Types from "./api.types";
import { ApiBase, ApiReturnType } from "./api-base";
import { API_VERSION_PREFIX } from "../constants";
import { PMTaskType, VendorTaskType } from "../constants_ts";
import { SimplePostResult } from "./api.types";

/**
 * Manages all requests to the API.
 */
console.log("DEFAULT_API_CONFIG", DEFAULT_API_CONFIG);
export class Api extends ApiBase {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
  }

  login(username: string, password: string): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/authenticate/?type=ADMIN`, {
      email: username,
      password,
    });
  }

  forgotPassword(email: string): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/forgot_password_admin/`, { email: email });
  }

  resetPassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/reset_password_admin/`, data);
  }

  blockUser(userId: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/${userId}/block/`);
  }

  reactivateUser(userId: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/${userId}/reactivate/`);
  }

  deleteAccount(userId: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(`${API_VERSION_PREFIX}/users/${userId}/`);
  }

  changePassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/change-password/`, data);
  }

  activateAccount(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/activate-user/`, data);
  }

  getUserTraffic(): ApiReturnType<Types.GenericResponse> {
    return this.simple_get(`${API_VERSION_PREFIX}/users/user_traffic_overview/`);
  }

  newCustomers(): ApiReturnType<Types.GenericResponse> {
    return this.simple_get(`${API_VERSION_PREFIX}/client/new_clients_overview/`);
  }

  ageGroupCustomers(): ApiReturnType<Types.GenericResponse> {
    return this.simple_get(`${API_VERSION_PREFIX}/client/age_distribution_overview/`);
  }

  getTermsConditions(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/terms_and_conditions/`);
  }

  getPrivacyPolicy(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/privacy_policy/`);
  }

  getFeedBack(searchParams: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/feedbacks/`, searchParams);
  }

  getDrivers(searchParams: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/driver/`, searchParams)
  }

  approveDriver(driverId: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/driver/${driverId}/approve/`)
  }

  isCompanyDriver(driverId: number, data: any) {
    return this.simple_patch(`${API_VERSION_PREFIX}/driver/${driverId}/is_company/`, data)
  }

  isUpdateClientType(customer_id: number, data: any) {
    console.log(data);

    return this.simple_patch(`${API_VERSION_PREFIX}/client/${customer_id}/update_client_type/`, data)
  }

  denyDriver(driverId: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/driver/${driverId}/deny/`)
  }

  requestMoreInfoDriver(driverId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/driver/${driverId}/request_more_info/`, data)
  }

  createTank(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/store-management/tank/`, data)
  }

  updateTank(tankId: number, data: any) {
    return this.simple_patch(`${API_VERSION_PREFIX}/store-management/tank/${tankId}/`, data)
  }

  getTanks(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/store-management/tank/`, data)
  }
  getFee(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/store-management/fee/`, data)
  }

  updateFee(data: any) {
    return data?.id
      ? this.simple_put(`${API_VERSION_PREFIX}/store-management/fee/${data?.id}/`, data)
      : this.simple_post(`${API_VERSION_PREFIX}/store-management/fee/`, data)
  }

  deleteTank(tankId: number) {
    return this.simple_delete(`${API_VERSION_PREFIX}/store-management/tank/${tankId}/`)
  }

  createStore(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/store-management/store/`, data)
  }

  updateStore(storeId: number, data: any) {
    return this.simple_patch(`${API_VERSION_PREFIX}/store-management/store/${storeId}/`, data)
  }

  getStores(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/store-management/store/`, data)
  }

  deleteStore(storeId: number) {
    return this.simple_delete(`${API_VERSION_PREFIX}/store-management/store/${storeId}/`)
  }

  getContent(searchParams: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/contents/`, searchParams);
  }

  getLanguages() {
    return this.simple_get(`${API_VERSION_PREFIX}/languages/`);
  }

  postContent(data: any): ApiReturnType<Types.GenericResponse> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/contents/`, data);
  }

  updateContent(data: any, id: number): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/contents/${id}/`, data);
  }

  deleteContent(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/contents/${id}/`);
  }

  sendInvitation(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/invite/`, data);
  }

  sendResponseToFeedBack(userId: number | string, data: any) {
    return this.simple_patch(`${API_VERSION_PREFIX}/feedbacks/${userId}/`, data);
  }

  getTemplates(searchParams: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/templates/`, searchParams);
  }

  getPointLegend() {
    return this.simple_get(`${API_VERSION_PREFIX}/points/legend/`);
  }

  updatePointLegend(data: any) {
    return this.simple_patch(`${API_VERSION_PREFIX}/points/legend/${data?.id}/`, data);
  }

  deleteTemplate(id: any) {
    return this.simple_delete(`${API_VERSION_PREFIX}/templates/${id}/`);
  }

  createTemplate(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/templates/`, data);
  }

  updateTemplate(data: any, id: number) {
    return this.simple_patch(`${API_VERSION_PREFIX}/templates/${id}/`, data);
  }

  getOrdersStatusAdmin(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/list_status/`, data);
  }

  getOrders(idProject: any, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/order/`, data);
  }

  getOrder(idProject: number | string, orderId: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/`);
  }

  getRegularOrder(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/regular/`, data);
  }

  assignDriver(orderId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/order/regular/${orderId}/assign_driver/`, data);
  }

  getOrderDetail(idProject: any, orderId: number | string, version?: number | string) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/full/` +
      (version ? `?version=${version}` : "")
    );
  }

  getOrderVersions(idProject: number | string, orderId: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/versions/`);
  }

  getOrderActivity(idProject: number | string, orderId: number | string, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/`,
      data
    );
  }

  sendOrderMessage(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/send_message/`,
      data
    );
  }

  shareOrder(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/share_order/`,
      data
    );
  }

  projectManufacturers(idProject: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/manufacturers/`, data);
  }

  requestQuote(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/request_quote/`, data);
  }

  getCategories(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/category/`, data);
  }

  getManufacturers(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/`, data);
  }

  getManufacturersByCategory(category_id: Number, data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/by_category/`, {
      category_id,
      ...data,
    });
  }

  getCompanies(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/company/`, data);
  }

  getProjects(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/`, data);
  }

  getProject(id: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${id}/`);
  }
  getProjectCartSections(idProject: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/cart_section/`);
  }

  createProjectCartSection(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/cart_section/`, data);
  }

  addItemToCartOrQuote(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/order/add_item/`, data);
  }

  getProjectDashboardInfo(idProject: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/dashboard/`);
  }

  downloadOrderCutsheets(idProject: number | string, orderId: number | string, params: any) {
    return this.download_file_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/download_cutsheets/`,
      params
    );
  }

  downloadBrandDrawing(idproject: number | string, iddrawing: number | string) {
    return this.download_file_get(
      `${API_VERSION_PREFIX}/brand/${idproject}/drawing/${iddrawing}/files/`
    );
  }

  getProducts(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/`, data);
  }

  getProjectProducts(idProject: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/product/`, data);
  }

  getMyProfile(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/my-profile/`, data);
  }

  saveMyProfile(data: any, keys: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/my-profile/`, data);
  }

  getProduct(id: any, data: any = {}) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/${id}/`, data);
  }

  getProjectProduct(idProject: number | string, id: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/product/${id}/`, data);
  }

  createProduct(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/product/`, data);
  }

  deleteProduct(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/product/${id}/`);
  }

  saveMiscellaneousCost(idProject: number | string, orderId: number | string, misc_cost: any) {
    return this.simple_id_save_or_create(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/miscellaneous_costs`,
      misc_cost
    );
  }

  deleteMiscellaneousCost(
    idProject: number | string,
    orderId: number | string,
    miscCostId: number | string
  ) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/miscellaneous_costs/${miscCostId}/`
    );
  }

  deleteOrderLine(
    idProject: number | string,
    orderId: number | string,
    orderLineId: number | string
  ) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/order_line/${orderLineId}/`
    );
  }

  updateQtyOrderLine(
    idProject: number | string,
    orderId: number | string,
    orderLineId: number | string,
    quantity: number | string
  ) {
    return this.simple_put(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/order_line/${orderLineId}/`,
      { quantity }
    );
  }

  deleteOrder(idProject: number | string, orderId: number | string) {
    return this.simple_delete(`${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/`);
  }

  doOrderTask(
    idProject: number | string,
    orderId: number | string,
    task: PMTaskType,
    message: string
  ) {
    let data: any = { action: task };
    if (message) data["message"] = message;
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/do_task/`,
      data
    );
  }
  shareOrderTask(idProject: number | string, orderId: number | string, data: any) {
    // let data: any = {action: task}
    // if (message) data['message'] = message
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/share_order/`,
      data
    );
  }

  doOrderVendorTask(idProject: number | string, orderId: number | string, task: VendorTaskType) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/do_task_vendor/`,
      { action: task }
    );
  }
  approveContract(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/approve_contract/`,
      data
    );
  }

  updateProduct(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/product/${data?.id}/`,
      data
    );
  }

  getProductsByManufacturer(manufacturer_id: Number, data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/by_manufacturer/`, {
      manufacturer_id,
      ...data,
    });
  }

  deleteProject(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/project/${id}/`)
  }

  createProject(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/`, data)
  }

  editProject(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/project/${data?.id}/`, data)
  }

  addDeleteProjectManager(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${data?.id}/project_manager/`, data)
  }

  sendEmailToManufacturer(projectId: string | number, data: any): ApiReturnType<SimplePostResult> {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${projectId}/send_email_to_manufacturer/`,
      data
    );
  }

  getProjectPackage(id: number | string, idPackage: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${id}/package/${idPackage}/`)
  }

  createCompany(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/company/`, data)
  }

  editCompany(data: any, keys: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/company/${data?.id}/`,
      data
    );
  }

  deleteCompany(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/company/${id}/`)
  }

  createRegion(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/region/`, data)
  }

  editRegion(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/region/${data?.id}/`, data)
  }

  deleteRegion(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/region/${id}/`)
  }

  reSendAdminUserEmail(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/user/${id}/email/`)
  }

  getAllUsers(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/users/`, data)
  }

  getUserData(id: number): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/users/${id}/`)
  }

  deleteUser(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/users/${id}/`)
  }

  editUser(data: any, id: number): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/users/${id}/`, data)
  }

  sendFlag(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/${data?.id}/flag/`, data)
  }

  createUserAdmin(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/users/`, data)
  }

  getAllBroadcasts(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/broadcast/`)
  }

  updateBroadcasts(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/broadcast/${data?.id}/status/`, data)
  }

  createCategory(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/category/`, data)
  }

  editCategory(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/category/${data?.id}/`,
      data
    );
  }

  deleteCategory(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/category/${id}/`)
  }

  getBackgroundImage() {
    return this.simple_get(`${API_VERSION_PREFIX}/background-image/`)
  }

  updateBackgroundImage(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/background-image/`, data)
  }

  getAnalyticsInitialData() {
    return this.simple_get(`${API_VERSION_PREFIX}/analytics/overview/`)
  }

  downloadAnalyticsReport(data: any) {
    return this.download_file_post(`${API_VERSION_PREFIX}/analytics/download/`, data)
  }

  getBrands(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/brand/`, data)
  }

  getBrand(id: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/brand/${id}/`)
  }

  deleteBrand(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/brand/${id}/`)
  }
  duplicateBrand(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(API_VERSION_PREFIX + `/brand/${id}/duplicate_brand/`)
  }

  createManufacturer(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/manufacturer/`, data)
  }

  editManufacturer(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/manufacturer/${data?.id}/`,
      data
    );
  }

  deleteManufacturer(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/manufacturer/${id}/`)
  }

  addDeleteSupplyManager(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/manufacturer/${data?.id}/supply_manager/`, data)
  }

  createBrand(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/brand/`, data)
  }

  updateBrand(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/brand/${data?.id}/`,
      data
    );
  }

  getNotificationsAdmin(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/notifications/`, data)
  }

  createNotification(data?: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/notifications/`, data)
  }

  sendNotification(id: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/notifications/${id}/send_notification/`)
  }

  updateNotification(data: any, id: number) {
    return this.simple_patch(`${API_VERSION_PREFIX}/notifications/${id}/`, data)
  }

  deleteNotification(id?: any) {
    return this.simple_delete(`${API_VERSION_PREFIX}/notifications/${id}/`)
  }

  markAsRead(id: number | string) {
    return this.simple_post(`${API_VERSION_PREFIX}/notification/${id}/mark_as_read/`)
  }

  hasUnreadNotifications() {
    return this.simple_get(`${API_VERSION_PREFIX}/notification/has_unread/`)
  }
  hasUnreadMessages() {
    return this.simple_get(`${API_VERSION_PREFIX}/conversation/has_unread/`)
  }

  getProjectContactList(idProject: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/contact_list/`)
  }

  getConversationUsers(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/conversation/`, data)
  }

  getConversation(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/conversation/conversation_detail/`, data)
  }

  setDevice(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/set-device/`, data);
  }

  getCustomOrder(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/custom/`, data);
  }

  updateCustomOffer(id: number, data: any) {
    return this.simple_put(`${API_VERSION_PREFIX}/order/custom/${id}/update-quote/`, data);
  }

  getCustomOrderChoices() {
    return this.simple_get(`${API_VERSION_PREFIX}/order/custom/choices/`);
  }
}
