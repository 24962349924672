import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../services/helpers";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import warningIcon from "../../assets/icons/warningCircle.svg"
import prohibitIcon from "../../assets/icons/Prohibit.svg"
import trashIcon from "../../assets/icons/TrashSimple.svg"
import { Grid } from "@mui/material";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { ROUTES } from "../../services/constants";
import { MODAL_NAME, USER_STATUS } from "../../utils/helpers";
import reactive from "../../assets/icons/reactiveUser.svg";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import pxToRem from "../../assets/theme/functions/pxToRem";

function AdminAddNewTank() {
  const api = useApi()
  const navigate = useNavigate();
  const { tankId } = useParams();
  const { state } = useLocation();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const createTank = (data) => {
    setLoading(true)
    api.createTank(data).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_STORE_MANAGEMENT)
      },
      successMessage: 'Tank created successfully',
      errorMessage: 'Error creating tank',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const updateTank = (data) => {
    setLoading(true)
    api.updateTank(tankId, data).handle({
      onSuccess: () => {
        navigate(ROUTES.ADMIN_STORE_MANAGEMENT)
      },
      successMessage: 'Tank updated successfully',
      errorMessage: 'Error updating tank',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const validationSchema =
    Yup.object().shape({
      tank: Yup.string().required(),
      available_amount: Yup.number().min(0).required(),
      exchange_price: Yup.number().min(0).required(),
      buying_price: Yup.number().min(0).required(),
    })

  const initialValues = {
    tank: state?.tank || "",
    available_amount: state?.available_amount || '',
    exchange_price: state?.exchange_price || '',
    buying_price: state?.buying_price || '',
  };

  return (
    <AdminLayout
      title={'Store Management'}
    >
      <MDBox display={'flex'} alingItems={'start'} width={"100%"}>
        <MDBox width={{sx:"100%", sm:'60%'}}>
          <MDBox
            borderRadius={"10px"}
            p={3}
            sx={{boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.25)"}}
          >
            <MDTypography color={"black"} variant={"h3"} mb={4}>
              Add new Tank
            </MDTypography>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              enableReinitialize
              onSubmit={values => {
                if (tankId) updateTank(values)
                else createTank(values);
              }}
            >
              {({errors, isValid, dirty}) => (
                <Form>
                  <MDBox display={"flex"} flexDirection={"column"} gap={3}>
                    <FormikInput name={'tank'} label={'Tank Title'} errors={errors}/>
                    <FormikInput name={'available_amount'} label={'Available Amount'} type={'number'} errors={errors}/>
                    <MDBox display={'flex'} gap={3}>
                      <FormikInput name={'exchange_price'} label={'Exchange Price'} type={'number'} errors={errors} width={"50%"}/>
                      <FormikInput name={'buying_price'} label={'Buying Price'} type={'number'} errors={errors} width={"50%"}/>
                    </MDBox>

                    <MDButton
                      variant="contained"
                      color="primary"
                      loading={loading}
                      disabled={loading || !isValid}
                      sx={{maxWidth: pxToRem(280)}}
                      type='submit'
                    >
                      Save tank
                    </MDButton>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </MDBox>
        </MDBox>
      </MDBox>

    </AdminLayout>
  );
}

export default AdminAddNewTank;
